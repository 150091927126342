.minimum-booked-time .content {
  position: relative;
}

.minimum-booked-time .content .antdSelect {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 90px;
}

.minimum-booked-time .content .antdSelect .ant-select-selector {
  height: 3.375rem;
  font-family: "ff_md";
  font-size: 0.938rem;
  line-height: 1.25rem;
}

.minimum-booked-time
  .content
  .antdSelect
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 3.375rem;
}

.minimum-booked-time
  .content
  .antdSelect
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 3.375rem;
}

.minimum-booked-time .content .antdSelect:hover .ant-select-selector {
  border-color: #ced4da !important;
}
