/* Color scheme of the project Approved Date: Friday, 28 April 2023 */
:root {
  --primary: #0e344b;
  --secondary: #c99d4c;
  --light: #f0f7fb;
  --light-md: #646c74;
  --dark: #090909;
  --light-primary: rgba(233, 242, 248, 1);
  --white: #ffffff;
  --border-radius-large: 18px;
  --border-radius-normal: 12px;
  --border-radius-small: 6px;
  --sucess: rgba(25, 115, 44, 1);
  --error: rgba(209, 15, 15, 1);
  --primary-opacity: rgb(14 52 75 / 10%);
  --b-shadow: rgba(149, 157, 165, 0.1) 0px 0px 24px;
  --yellow: #ffd43d;
}

.c-primary {
  background-color: var(--primary) !important;
}

.c-secondary {
  background-color: var(--secondary) !important;
}

.c-light {
  background-color: var(--light) !important;
}

.c-light-md {
  background-color: var(--light-md) !important;
}

.c-dark {
  background-color: var(--dark) !important;
}

.c-light-primary {
  background-color: var(--light-primary) !important;
}

.c-success {
  background-color: var(--sucess) !important;
}

.c-error {
  background-color: var(--error) !important;
}

.c-white {
  background-color: var(--white) !important;
}

.c-primary-opacity {
  background-color: var(--primary-opacity) !important;
}

.txt-primary {
  color: var(--primary) !important;
}

.txt-secondary {
  color: var(--secondary) !important;
}

.txt-light {
  color: var(--light) !important;
}

.txt-light-md {
  color: var(--light-md) !important;
}

.txt-dark {
  color: var(--dark) !important;
}

.txt-primary {
  color: var(--primary) !important;
}

.txt-secondary {
  color: var(--secondary) !important;
}

.txt-light {
  color: var(--light) !important;
}

.txt-light-md {
  color: var(--light-md) !important;
}

.txt-dark {
  color: var(--dark) !important;
}

.txt-error {
  color: var(--error) !important;
}

.txt-white {
  color: var(--white) !important;
}

.txt-yellow {
  color: var(--yellow) !important;
}

.txt-success {
  color: var(--sucess) !important;
}

/*  Radius */
.border-l {
  border-radius: var(--border-radius-large);
}

.border-n {
  border-radius: var(--border-radius-normal);
}

.border-s {
  border-radius: var(--border-radius-small);
}

.border-1 {
  border: 1px solid rgba(14, 52, 75, 0.1);
}

.border-1_5 {
  border: 1.5px solid rgba(14, 52, 75, 0.1);
}

.border-2 {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

/* Box Shadow */
.b-shadow {
  box-shadow: var(--b-shadow);
}

/* Text Wrap */
.text-wrap {
  word-wrap: break-word;
}

.AdminIndex [type="checkbox"],
.CustomerIndex [type="checkbox"],
.ant-modal [type="checkbox"] {
  font-size: 22px;
}

.cursor-pointer-default {
  cursor: default !important;
}
