/* Manage Car listing*/
.listOfCarStyle.premiumCar * {
  color: var(--secondary) !important;
}

.listOfCarStyle.premiumCar svg * {
  /* stroke: #fff; */
}

.listOfCarStyle.premiumCar svg [fill="black"],
.listOfCarStyle.premiumCar svg [fill="#272727"],
.listOfCarStyle.premiumCar svg [fill="var(--primary)"],
.listOfCarStyle.premiumCar .currentLocationMinHeight svg * {
  fill: var(--secondary) !important ;
}

.listOfCarStyle.premiumCar .FeatureList,
.listOfCarStyle.premiumCar .UCLFeatures > div,
.listOfCarStyle.premiumCar .manageCarImage img {
  border-color: rgba(201, 157, 76, 0.4);
}

.listOfCarStyle.premiumCar hr {
  border-top-color: var(--secondary);
}

.listOfCarStyle.premiumCar .ant-rate-star.ant-rate-star-full * {
  fill: var(--secondary) !important;
}

.listOfCarStyle.premiumCar .ant-rate-star.ant-rate-star-zero *,
.listOfCarStyle.premiumCar .ant-rate-star.ant-rate-star-half * {
  fill: rgba(201, 157, 76, 0.4);
}

.listOfCarStyle.premiumCar button {
  background-color: var(--secondary) !important;
  color: var(--primary) !important;
}

.listOfCarStyle.premiumCar button * {
  color: var(--primary) !important;
}

.listOfCarStyle.premiumCar.svgFill * {
  fill: #fff !important;
}

/* Manage Car - Detail */
.ChangeAllPremiumCar .extraDetailsBackground {
  background: var(--primary);
}

.ChangeAllPremiumCar .extraDetailsBackground * {
  color: var(--secondary);
}

/* Booking */
.listOfCarStyle.premiumCar .bookingListCard svg {
  border: 1px solid #fff;
  border-radius: 50%;
}

.AdminIndex .bookingListingCard .listOfCarStyle.premiumCar.isActive {
  border-color: var(--secondary) !important;
  border-width: 2px !important;
}

/* Customer */
.yourBookingRideList .listOfCarStyle.premiumCar .PickUpPickDrop .ant-steps-item-title {
  color: var(--secondary) !important;
}

.yourBookingRideList .listOfCarStyle.premiumCar .PickUpPickDrop .ant-steps-item-description {
  color: rgba(256, 256, 256, 0.7) !important;
}

.yourBookingRideList .listOfCarStyle.premiumCar .PickUpPickDrop .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: var(--secondary) !important;
}

.yourBookingRideList .listOfCarStyle.premiumCar .PickUpPickDrop .ant-steps-item-icon {
  border-color: var(--secondary) !important;
}

.yourBookingRideList .listOfCarStyle.premiumCar button.bg-transparent {
  background-color: transparent !important;
  color: var(--secondary) !important;
}

.yourBookingRideList .listOfCarStyle.premiumCar button.bg-transparent * {
  color: var(--secondary) !important;
}

.listOfCarStyle.premiumCar .payTotalAmount svg *,
.listOfCarStyle.premiumCar .freeCancellation svg * {
  fill: var(--secondary) !important ;
}

.listOfCarStyle.premiumCar .border-1 {
  border-color: rgba(201, 157, 76, 0.4) !important;
}

.listOfCarStyle.premiumCar .bg-white {
  background-color: var(--secondary) !important;
}

.listOfCarStyle.premiumCar .bg-white span {
  color: var(--primary) !important;
}

.listOfCarStyle.premiumCar .bg-white.text-danger {
  background-color: #fff !important;
  color: red !important;
}

.listOfCarStyle.premiumCar .BookingConfirmDescription svg * {
  fill: var(--secondary) !important ;
}

.listOfCarStyle.premiumCar .bookingPikerLocation [stroke="#0E344B"] {
  stroke: var(--secondary) !important ;
  fill: transparent !important;
}

.BookingConfirm .listOfCarStyle.premiumCar .bookingPikerLocation .drawline:before {
  background-color: rgba(201, 157, 76, 0.4) !important;
}

.listOfCarStyle.premiumCar .multiColorCarIsSelected [fill="#0E344B"] {
  fill: var(--secondary) !important ;
}

.listOfCarStyle.premiumCar .searchCarImageSection img {
  background: #fff !important;
}

.selectColorListOfCar .active .listOfCarStyle.premiumCar {
  border-color: var(--secondary) !important;
}

.SearchResult .listOfCarStyle.premiumCar .carColor span,
.bookingConfirmDetails .listOfCarStyle.premiumCar .carColor span,
.yourBookingRideList .listOfCarStyle.premiumCar .carColor span,
.listOfCarStyle.premiumCar .FeatureList .carColor span {
  border-color: #fff !important;
}

.Dashboard .dashBoardMPB .MostPopularBooking .listOfCarStyle.premiumCar .d-inline-flex {
  border-color: #fff !important;
}

.listOfCarStyle.premiumCar .bookingCancelCross [fill="#0E344B"] {
  fill: var(--secondary) !important ;
}

.listOfCarStyle.premiumCar .BookingAdminContact [fill="#FFD43D"] {
  fill: var(--secondary) !important ;
}

.listOfCarStyle.premiumCar .BookingAdminContact [fill="black"] {
  fill: #fff !important;
}

.SelectedColorCarCard .listOfCarStyle.premiumCar .FeatureList svg * {
  fill: var(--primary) !important ;
}

/*  */
.bookingPikerLocation svg {
  min-width: 15px;
  min-height: 15px;
}

.AdminIndex .bookingListingCard > div.NotActive {
  border-width: 2px !important;
}

.listOfCarStyle.premiumCar .featuredCarTag {
  color: #fff !important;
}

.listOfCarStyle.premiumCar .greenDotIcon img,
.listOfCarStyle.premiumCar .crossRedDotIcon img {
  border-radius: 50%;
  border: 1px solid #fff !important;
}
