body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "ff_rg", sans-serif !important;
	font-size: 16px !important;
	line-height: 1.5 !important;
	color: var(--dark) !important;
}
* {
	border: none;
	outline: none;
}
a {
	text-decoration: none !important;
	color: inherit !important;
}
/* FONTS */
.ff_rg {
	font-family: "ff_rg", Arial, Helvetica, sans-serif;
}

.ff_rg {
	font-family: "ff_rg", Arial, Helvetica, sans-serif;
}

.ff_md {
	font-family: "ff_md", Arial, Helvetica, sans-serif;
}

.ff_bd {
	font-family: "ff_bd", Arial, Helvetica, sans-serif;
}

.ff_bd {
	font-family: "ff_bd", Arial, Helvetica, sans-serif;
}
