/* Floating Button */
@media screen and (max-width: 767.98px) {
  .mobileSizeFloatingButton,
  .bookingSummary_PriceDetail .payBookingFloatingButton {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100% !important;
    max-width: 100%;
    border-radius: 0;
    z-index: 999;
    margin-bottom: 0 !important;
    height: 54px !important;
  }

  .DiffCurrentStartTime {
    background-color: var(--secondary) !important;
    color: #fff !important;
    position: fixed;
    left: 12px;
    bottom: 46px;
    font-size: 12px !important;
    text-align: left !important;
    font-family: "ff_rg";
    width: 100%;
    z-index: 999;
  }

  .FooterSpace .Footer {
    margin-bottom: 54px;
  }

  .FooterSpaceMore .Footer {
    margin-bottom: 88px;
  }

  .bookingSummary_PriceDetail .payBookingFloatingButton.c-primary-opacity {
    background-color: var(--light) !important;
  }

  .mobileSizeFloatingButton.btnDisable {
    background-color: #e7eaed !important;
  }

  .isIOS .mobileSizeFloatingButton,
  .isIOS .bookingSummary_PriceDetail .payBookingFloatingButton {
    bottom: 20px !important;
  }

  .isIOS .DiffCurrentStartTime {
    bottom: 66px;
  }

  .isIOS .mobileSizeFloatingButton:before,
  .isIOS .bookingSummary_PriceDetail .payBookingFloatingButton:before {
    content: "";
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20px;
    background: #fff;
    left: 0;
  }
}

@media screen and (max-width: 600px) {
  .selectChildSeat .ant-modal-content {
    padding: 1rem;
  }

  .selectChildSeat .ant-modal-content .selectAOption .ff_md {
    font-size: 13px;
    line-height: 17px;
  }

  .selectChildSeat .ant-modal-content .selectAOption .ff_rg {
    font-size: 9px;
    line-height: 10px;
  }

  .selectChildSeat .ant-modal-content .selectAOption .ff_bd {
    font-size: 14px;
    line-height: 16px;
  }

  .selectChildSeat .ant-modal-content .selectAOption .seatSquareButton {
    font-size: 14px;
    line-height: 12px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    padding-right: 1px;
  }

  .NewModelPopUp .antDButton button {
    height: 38px;
    font-size: 12px;
    line-height: 16px;
  }

  .selectChildSeat .ant-modal-content .selectChildSeatTitle,
  .NewModelPopUp .ant-modal-content .bookingSelectOptionTitle {
    font-size: 16px;
    line-height: 21px;
  }

  .NewModelPopUp .ant-modal-content .bookingSelectOptionsubHeading {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  .NewModelPopUp .ant-modal-content .bookingSelectOptionsubHeadingDesc {
    font-size: 10px;
    line-height: 13px;
    text-align: center;
  }

  .NewModelPopUp .ant-modal-content .bookingSelectOptionchildren .mb-0 {
    font-size: 14px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767.98px) {
  .homePage .banner_section .bannerSectionHeading,
  .carRentalWork .carRentalWorkSectionHeading,
  .MeetSomeMoreCar .MeetSomeMoreCarSectionHeading,
  .PickupStyle .PickerLocationSectionHeading,
  .NewsLetter .have_some_questions .f38,
  .PromoBanner .PromoBannerImage h4 {
    font-size: 22px;
    line-height: 29px;
  }

  .NewsLetter .have_some_questions .ff_rg,
  .PromoBanner .PromoBannerImage p {
    font-size: 13px;
    line-height: 24px;
  }

  .NewsLetter .have_some_questions img {
    width: 75px;
  }

  .NewsLetter .h54,
  .CareerPage .careerSubmitBtn {
    height: 48px;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }

  .homePage .banner_section .banner-select-option {
    width: 100% !important;
    margin-top: 275px;
    padding: 20px !important;
  }

  .homePage .banner_section .banner-select-option button {
    width: 100%;
  }

  .homePage .banner_section .PickUpLocation .location,
  .homePage .banner_section .DatepickerRange .location {
    height: 50px;
  }

  .carRentalWork .carRentalWorkSectionDescription br,
  .MeetSomeMoreCar .MeetSomeMoreCarDescription br {
    display: none;
  }

  .carRentalWork .row > div {
    max-width: 85%;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .PickupStyle > .w-25 {
    width: 90% !important;
  }

  /* Search Page */
  .SearchResult .SearchChangeDate {
    background-color: rgba(223, 227, 230, 0.3);
    padding: 1rem !important;
  }

  .SearchResult .SearchChangeDate .row {
    margin: 0 !important;
  }

  .SearchResult .SearchChangeDate .row .col-12 {
    justify-content: space-between;
    padding: 0;
  }

  .SearchResult .SearchChangeDate .row .col-12 .ms-5 {
    margin-left: 0 !important;
  }

  .SearchResult .SearchChangeDate .row .col-4 {
    display: none;
  }

  .SearchResult .SearchChangeDate .selectPickerLabel {
    font-size: 14px;
  }

  .SearchResult .ChangePickup .f14,
  .SearchResult .ChangeDropOff .f14 {
    font-size: 12px;
  }

  .SearchResult .searchShowOnMap,
  .SearchResult .searchFilter,
  .SearchResult .searchCarListingPage .sideBarRight .SearchChangeDate,
  .SearchResult .searchCarListingPage .sideBarRight .PriceFilter,
  .SearchResult .searchFilter,
  .applyFilterMobile .searchFilterHeading,
  .applyFilterMobile .mobile-d-none,
  .SearchFilterCard .VehicleInfo img {
    display: none !important;
  }

  .applyFilterMobile .overflow-scroll {
    max-height: unset !important;
    overflow: unset !important;
    margin-bottom: 24px;
  }

  .SearchResult .searchCarListingPage .sideBarLeft .mobileFilter .PriceFilter {
    right: 0;
  }

  .SearchResult .searchCarListingPage .sideBarRight .searchCarListTitle {
    font-size: 17px;
    line-height: 22px;
    margin-top: 25px;
  }

  .applyFilterMobile .ant-modal-content {
    border-radius: 0;
    padding: 0;
  }

  .applyFilterMobile .CustomCheckBox .form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .applyFilterMobile .CustomCheckBox .form-check-input[type="checkbox"] {
    border-radius: 50%;
    font-size: 24px;
    margin-top: 0;
  }

  .applyFilterMobile .CustomCheckBox .form-check-input:focus {
    box-shadow: none;
    border-color: #000000;
  }

  .applyFilterMobile .CustomCheckBox .form-check-label {
    font-family: "ff_md";
    font-size: 16px;
  }

  .applyFilterMobile .searchPassengers input,
  .applyFilterMobile .searchBags input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .SearchFilterCard .orderNumberTwo .mobile-d-none {
    display: none !important;
  }

  .SearchFilterCard .orderNumberOne {
    padding-right: 0;
  }

  .SearchFilterCard .orderNumberTwo {
    order: -1;
  }

  .SearchFilterCard .orderNumberTwo > div {
    display: block !important;
  }

  .SearchFilterCard .VehicleInfo {
    text-align: start !important;
  }

  .SearchFilterCard .VehicleInfo ul span.f14 {
    font-size: 12px;
  }

  .SearchFilterCard .VehicleInfo p.f12 {
    font-size: 10px;
  }

  .SearchFilterCard .VehicleInfo p.f12 .f14 {
    font-size: 11px;
  }

  /* search / cardID */
  .SearchCarDetails .SearchCarDetailsTitle,
  .SearchCarDetails .SearchCarDetailsBackLink {
    text-align: center !important;
    display: block !important;
  }

  .SearchCarDetails .SearchCarDetailsTitle {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0.25px;
  }

  .SearchCarDetails .SearchCarDetailsBackLink {
    font-size: 11px;
    line-height: 15px;
  }

  .SearchCarDetails .SearchCarDetailsList .carlistFooter .carCardFooter > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* search : carID */
  .SearchCarDetails .PickUpDrop .carModelDetails .carName,
  .SearchCarDetails .carDescriptionTitle,
  .CustomerIndex .BookingIndex .PickUpDrop .carModelDetails .carName {
    font-size: 17px;
    line-height: 22px;
  }

  .SearchCarDetails .PickUpDrop .carModelDetails .carModelandType,
  .CustomerIndex .BookingIndex .PickUpDrop .carModelDetails .carModelandType {
    font-size: 12px;
    line-height: 16px;
  }

  .SearchCarDetails .PickUpDrop .displayLocation .ant-steps-item-title,
  .SearchCarDetails .PickUpDrop .displayLocation .ant-steps-item-description,
  .SearchCarDetails .PickUpDrop .pickUPDropProcessbar > h4,
  .SearchCarDetails .searchCarDetailsFeatureList p,
  .CustomerIndex
    .BookingIndex
    .PickUpDrop
    .displayLocation
    .ant-steps-item-title,
  .CustomerIndex
    .BookingIndex
    .PickUpDrop
    .displayLocation
    .ant-steps-item-description {
    font-size: 14px;
    line-height: 18px;
  }

  .SearchCarDetails .searchCarDetailsFeatureList img {
    width: 14px;
  }

  .SearchCarDetails .carDescriptionDescription {
    font-size: 14px;
    line-height: 28px;
  }

  .CustomerIndex .SearchCarDetails .customerSearchLocation {
    /* order: 2;
    padding-top: 1.5rem; */
  }

  .CustomerIndex .SearchCarDetails .customerFeatureList {
    order: 1;
  }

  /* Booking unAthentication Manage Booking */
  .unAthentication .row .unAthenticationManageBooking {
    margin-bottom: 1rem;
  }

  .unAthentication .row .unAthenticationManageBooking .BookingListTitle {
    margin-bottom: 1rem;
  }

  .unAthentication
    .row
    .unAthenticationManageBooking
    .manageBookingSubmitHandler {
    margin: 0 auto;
    display: block;
  }

  .unAthentication
    .row
    .unAthenticationManageBooking
    .manageBookingSubmitHandler,
  .unAthentication .row .manageBookingLoginHandler {
    min-width: 125px;
  }

  /* Booking Confirm */
  .BookingConfirm .BookingConfirmTitle,
  .BookingList .bookingListNotAvaialable h4 {
    font-size: 22px;
    line-height: 29px;
  }

  .BookingList .bookingListNotAvaialable h4,
  .BookingList .bookingListNotAvaialable p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .BookingConfirm img.BookingSuccessIcon {
    width: 24px;
  }

  .BookingConfirm .bookingConfirmCarDetails .BookingConfirmCar {
    display: flex;
    flex-direction: row-reverse;
  }

  .BookingConfirm .bookingConfirmCarDetails .BookingConfirmCar > img,
  .BookingConfirm .bookingConfirmCarDetails .BookingConfirmCar > div {
    width: 50%;
  }

  .BookingConfirm .BookingConfirmCar .carName {
    font-size: 15px;
    line-height: 20px;
  }

  .BookingConfirm .BookingConfirmCar .carType {
    font-size: 12px;
    line-height: 16px;
  }

  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .text-end {
    text-align: start !important;
  }

  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .text-end
    > h4 {
    font-size: 14px;
    line-height: 15px;
  }

  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .text-end
    > p {
    font-size: 11px;
    line-height: 15px;
  }

  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .text-end
    > p
    span,
  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .bookingNumber {
    font-size: 13px;
  }

  .BookingConfirm .DownloadPDF {
    margin: 0 auto !important;
  }

  .BookingConfirm .BookingDocUpload > h4 {
    font-size: 17px;
    line-height: 22px;
  }

  /* Your Booking - Upcoming, Active, All */
  .BookingList .BookingListTitle {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0.25px;
  }

  .BookingList .BookingRideType {
    overflow-x: scroll;
  }

  .BookingList .BookingRideType::-webkit-scrollbar {
    display: none;
  }

  .BookingList .BookingListDisplayLocation {
    padding-left: 10px;
  }

  /* Your Booking - Edit Booking */
  .BookingEdit .BookingEditTitle,
  .BookingEdit .BookingEditBackLink {
    text-align: center !important;
    display: block !important;
  }

  .BookingEdit .BookingEditTitle {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0.25px;
  }

  .BookingEdit .BookingEditBackLink {
    font-size: 11px;
    line-height: 15px;
  }

  .BookingEdit .FeaturesTitle,
  .BookingEdit .carDescriptionTitle,
  .BookingEdit .PickUpDrop .carModelDetails .carName {
    font-size: 17px;
    line-height: 22px;
  }

  .BookingEdit .BookingEditFeatureList p,
  .BookingEdit .PickUpDrop .pickUPDropProcessbar > h4,
  .BookingEdit .PickUpDrop .displayLocation .ant-steps-item-title,
  .BookingEdit .PickUpDrop .displayLocation .ant-steps-item-description {
    font-size: 14px;
    line-height: 18px;
  }

  .BookingEdit .BookingEditFeatureList img {
    width: 14px;
  }

  .BookingEdit .carDescriptionDescription {
    font-size: 14px;
    line-height: 28px;
  }

  .BookingEdit .IndivisualAndBusinessDetails p.ff_rg,
  .BookingEdit .PickUpDrop .carModelDetails .carModelandType {
    font-size: 12px;
    line-height: 16px;
  }

  .BookingEdit .IndivisualAndBusinessDetails p.ff_md {
    font-size: 13px;
    line-height: 17px;
  }

  .BookingEdit .PickUpLocation .location,
  .BookingEdit .DatepickerRange .location {
    min-height: 50px;
  }

  /* Price Details */
  .BookingPriceDetails .bookingPriceTitle,
  .PriceSpecification .priceSpecificationHeading {
    font-size: 20px;
    line-height: 26px;
  }

  .BookingPriceDetails .BookingPriceLabel {
    font-size: 14px;
    line-height: 18px;
  }

  .BookingPriceDetails .BookingPriceDescription {
    font-size: 12px;
    line-height: 16px;
  }

  .BookingPriceDetails .BookingPriceCost {
    font-size: 14px;
    line-height: 18px;
  }

  .PriceSpecification button img {
    width: 24px;
  }

  /* Your Booking View Details */
  .yourBookingViewDetials .BookingViewTitle,
  .yourBookingViewDetials .BookingViewBackLink {
    text-align: center !important;
    display: block !important;
  }

  .yourBookingViewDetials .BookingViewTitle {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0.25px;
  }

  .yourBookingViewDetials .BookingViewBackLink {
    font-size: 11px;
    line-height: 15px;
  }

  .yourBookingViewDetials .FeaturesTitle,
  .yourBookingViewDetials .carDescriptionTitle,
  .yourBookingViewDetials .PickUpDrop .carModelDetails .carName,
  .yourBookingViewDetials
    .ViewDetailsMobile
    .BookingCarPhotos
    .CarPhotosListTitle {
    font-size: 17px;
    line-height: 22px;
  }

  .yourBookingViewDetials .BookingEditFeatureList p,
  .yourBookingViewDetials .PickUpDrop .pickUPDropProcessbar > h4,
  .yourBookingViewDetials .PickUpDrop .displayLocation .ant-steps-item-title,
  .yourBookingViewDetials
    .PickUpDrop
    .displayLocation
    .ant-steps-item-description,
  .yourBookingViewDetials
    .yourBookingViewDetialsDescription
    .carDescriptionDescription {
    font-size: 14px;
    line-height: 18px;
  }

  .yourBookingViewDetials .IndivisualAndBusinessDetails p.ff_rg {
    font-size: 12px;
    line-height: 16px;
  }

  .yourBookingViewDetials .IndivisualAndBusinessDetails p.ff_md {
    font-size: 13px;
    line-height: 17px;
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical {
    overflow-x: scroll;
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical::-webkit-scrollbar {
    display: none;
  }

  .ViewDetailMobileTab {
    font-size: 12px;
    line-height: 16px;
    min-width: 135px;
    padding-bottom: 10px;
    border-bottom: 2px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(39, 39, 39, 0.5);
    font-size: 16px;
  }

  .yourBookingViewDetials .ViewDetailMobileTab input {
    width: 0;
    height: 0;
    opacity: 0;
    min-width: unset;
    min-height: unset;
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical label:has(input:checked) {
    border-bottom: 2px solid rgba(39, 39, 39, 1);
    color: rgba(39, 39, 39, 1);
  }

  .BookingCarPhotos .carPhotosButton {
    border-radius: 12px;
    height: 46px;
    min-width: 150px;
    background-color: #ebebec;
    color: rgb(52, 58, 63);
  }

  .BookingCarPhotos .carPhotosButton input {
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    min-width: unset;
    min-height: unset;
  }

  .BookingCarPhotos .carPhotosButton:has(input:checked) {
    background: var(--primary) !important;
    color: #fff;
  }

  .yourBookingViewDetials
    .ViewDetailsMobile
    .BookingCarPhotos
    .CarPhotosList
    .carPhotoDescription
    h4 {
    font-size: 15px;
    line-height: 20px;
  }

  .yourBookingViewDetials
    .ViewDetailsMobile
    .BookingCarPhotos
    .CarPhotosList
    .carPhotoDescription
    p {
    font-size: 13px;
    line-height: 20px;
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical {
    position: relative;
  }

  /* Edit Car Photo Upload And Change */
  .EditRideCarPhoto .ant-modal-content {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
  }

  .EditRideCarPhotoHeader {
    border-bottom: 1px solid #d9d9d9;
  }

  .EditRideCarPhoto .carPhotosButton {
    border-radius: 12px;
    height: 46px;
    min-width: 150px;
    background-color: #ebebec;
    color: rgb(52, 58, 63);
  }

  .EditRideCarPhoto .carPhotosButton input {
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    min-width: unset;
    min-height: unset;
  }

  .EditRideCarPhoto .carPhotosButton:has(input:checked) {
    background: var(--primary) !important;
    color: #fff;
  }

  /* customer Profile */
  .customerProfile .customerProfileHeader {
    text-align: center;
  }

  .customerProfile .customerProfileTitle {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0.25px;
  }

  .customerProfileInfo .customerProfileName {
    font-size: 20px;
    line-height: 26px;
  }

  .customerProfileInfo .customerProfileEmail {
    font-size: 13px;
    line-height: 17px;
  }

  .customerProfileCategoryList .ProfileCategoryListType {
    overflow-x: scroll;
  }

  .customerProfileCategoryList .ProfileCategoryListType::-webkit-scrollbar {
    display: none;
  }

  .customerProfileCategoryList .ProfileCategoryListType > label {
    font-size: 12px;
    line-height: 16px;
  }

  /* Adjust Rental */
  .BookingIndex .AdjustRentalButton,
  .BookingIndex .driverDetailButton,
  .BookingIndex .bookingSummaryButton {
    margin: 0 auto;
  }

  .BookingIndex .bookingInCurrentStep {
    text-align: center;
  }

  .BookingIndex .bookingInCurrentStep a {
    font-size: 11px;
    line-height: 15px;
  }

  .BookingIndex .BookingSummary .personalDetailsTitle {
    font-size: 14px;
    line-height: 18px;
  }

  .BookingIndex .BookingSummaryCard .labelTitle {
    font-size: 12px;
    line-height: 16px;
  }

  .BookingIndex .BookingSummaryCard .labelVal {
    font-size: 13px;
    line-height: 17px;
  }

  .BookingIndex .BookingSummary .bookingID,
  .BookingIndex .BookingSummary .BookingSummaryCardTitle {
    font-size: 12px;
    line-height: 15px;
  }

  .BookingIndex .BookingSummary .BookingSummaryCardValue {
    font-size: 13px;
    line-height: 17px;
  }

  .BookingIndex .BookingSummary .BookingSummaryCardImage {
    width: 13px;
    height: 18px;
  }

  .BookingIndex .BookingSummary .BookingSummaryDetailsTitle {
    font-size: 17px;
    line-height: 22px;
  }

  .BookingIndex .PromoCode .promoCodeInput .form-control,
  .BookingIndex .PromoCode .promoCodeInput .form-input-type button,
  .BookingIndex .SearchPriceDetails .totalPay {
    height: 48px;
  }

  /* Static Page */
  .AboutUsBox .AboutUsBoxRight {
    margin-left: 0rem !important;
  }

  /* Pririoty - Search Details - Review */
  .customerWriteAReview .WriteAReview .carName {
    font-size: 16px;
    line-height: 20px;
  }

  .ReviewAvatar img {
    width: 40px;
    height: 40px;
  }

  .homePage .PickupStyle {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .homePage .PickupStyle .PickUpSlider {
    padding-bottom: 10px;
  }

  .homePageLocation .swiper-button-next,
  .homePageLocation .swiper-button-prev {
    width: 20px;
    height: 20px;
  }

  .homePageLocation .swiper-button-next {
    right: calc(50% - 30px) !important;
  }

  .homePageLocation .swiper-button-prev {
    left: calc(50% - 30px) !important;
  }

  .PickUpSlider .homePageLocation .swiper-wrapper {
    padding-bottom: 30px;
  }

  .homePageLocation .swiper-button-next:after,
  .homePageLocation .swiper-button-prev:after {
    font-size: 10px;
  }

  .CustomerIndex .SearchCarDetails {
    padding-bottom: 0;
  }

  .applyFilterMobile .customerSearchFilterHeader {
    z-index: 9;
  }

  .applyFilterMobile .customerSearchFilter {
    padding-top: 64px;
    max-height: 100vh;
    overflow-y: scroll;
    height: 100vh;
  }

  .BookingConfirm .BookingConfirmCar {
    order: -2;
  }

  .BookingConfirm .bookingFeatureListOrder {
    order: -1;
  }

  .PickUpSlider .PickupItem {
    margin: 0 16px !important;
  }

  .AboutUsBox .AboutUsBoxRight {
    min-height: auto;
    position: relative;
    top: -40px;
  }

  .AboutUsBox .AboutUsBoxLeft {
    min-height: 250px;
  }

  .france .nmofdrivercol {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .selectChildSeat .ant-modal-content .antDButton {
    text-align: end;
  }

  .CustomerIndex
    .customerNotification
    .customerNotificationBody
    .notificationTime {
    position: relative !important;
    top: 0;
    padding: 0 !important;
  }

  .bookingProcessbar.ant-steps.ant-steps-inline .ant-steps-item-tail:after {
    height: 5px;
  }

  .PickUpSlider .swiper-slide-active .PickupItem {
    background: var(--primary);
  }

  .PickUpSlider .swiper-slide-active .PickupDescription p {
    color: rgba(255, 255, 255, 1);
  }

  .PickUpSlider .swiper-slide-active .PickupDescription button {
    color: var(--light);
    background: var(--secondary) !important;
  }
}

@media screen and (max-width: 991px) {
  .homePage .banner_section .banner-select-option {
    width: 100% !important;
    margin-top: 275px;
    padding: 20px 20px 60px 20px !important;
  }

  .homePage .banner_section .banner-select-option button {
    width: 100%;
  }

  .homePage .banner_section .PickUpLocation .location,
  .homePage .banner_section .DatepickerRange .location {
    height: 50px;
  }

  .homePage .banner_section .banner_section_background {
    background-size: 375px;
    background-position: right 18%;
  }

  .CustomerIndex .BookingIndex .bookingTimeOut {
    min-width: 100px;
    height: 44px;
  }

  .BookingIndex
    .Progressbar
    .AdjustRental
    .BookingSelectDay
    .BookingSelectDayTitle,
  .BookingIndex .Progressbar .AdjustRental .ChildSeat .ChildSeatTitle,
  .BookingIndex .Progressbar .AdjustRental .SearchYesNo .SearchYesNoTitle {
    font-size: 15px;
    line-height: 20px;
  }

  .BookingIndex .Progressbar .AdjustRental .SearchYesNo .SearchYesNoTitle2,
  .BookingIndex .Progressbar .AdjustRental .SearchYesNo .SearchYesNoPrice,
  .BookingIndex
    .Progressbar
    .AdjustRental
    .BookingSelectDay
    .BookingSelectDayButton,
  .BookingIndex .Progressbar .AdjustRental .ChildSeat .ChildSeatButton {
    font-size: 12px;
    line-height: 16px;
  }

  .BookingIndex
    .Progressbar
    .AdjustRental
    .BookingSelectDay
    .BookingSelectDayImage {
    width: 20px;
  }

  .BookingIndex .DriverDetail .driverDetailsTitle {
    font-size: 13px;
    line-height: 17px;
  }

  .BookingIndex .DriverDetail .personalDetail h4,
  .BookingIndex .DriverDetail .businessDetail h4 {
    font-size: 17px;
    line-height: 22px;
  }

  .BookingIndex .DriverDetail .CustomerInput label {
    font-size: 12px;
    line-height: 16px;
  }

  .BookingIndex .DriverDetail .CustomerInput .form-control,
  .BookingIndex .DriverDetail .selectCountry .selectCountrySec {
    height: 48px;
    font-size: 15px;
    line-height: 20px;
  }

  .BookingIndex .DriverDetail .selectCountry .selectCountrySec {
    min-height: 48px;
  }

  .BookingIndex .DriverDetail .CustomCheckBox .form-check-label {
    font-size: 14px;
    line-height: 18px;
  }

  .BookingIndex .DriverDetail .setTypeRadio .RadioBox label {
    font-size: 13px;
    line-height: 17px;
    align-items: center;
    display: flex;
  }

  .BookingIndex .bookingInCurrentStep .PageHeading {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 0rem;
  }

  .SearchResult .SearchChangeDate .row .col-12 .md-5 img {
    transform: rotate(90deg);
  }

  .BookingConfirm .BookingDocUpload > .row > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  .CustomerIndex .customerNotification .ProfileHeader .ProfileHeaderLeft {
    text-align: center;
  }

  .CustomerIndex
    .customerNotification
    .ProfileHeader
    .ProfileHeaderLeft
    .NotificationBackLink {
    font-size: 11px;
    line-height: 15px;
  }

  .bookingSummary_PriceDetail .payBookingFloatingButton {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100% !important;
    max-width: 100%;
    border-radius: 0;
    z-index: 999;
    margin-bottom: 0 !important;
    height: 54px !important;
  }
}

@media screen and (max-width: 1200px) {
  .BookingList .BookingRideType > label {
    font-size: 12px;
    line-height: 16px;
  }
}

/* Home Page Responsive */
@media screen and (min-width: 576px) {
  .customerCarAvailable .carAvailableRadioBtn {
    position: relative;
    top: -0.05rem;
  }
}

@media screen and (min-width: 768px) {
  .SearchResult .searchChangeDateTitle,
    .SearchResult .mobileFilter,
    /* .SearchFilterCard .desktop-d-none, */
    .SearchResult .mobileCarPriceAndFeature,
    .SearchFilterCard .mobileCarPriceAndFeature,
    .ChangePickupDrop .searchChangeDateTitle {
    display: none !important;
  }

  /* search : carID */
  .SearchCarDetails .SearchCarDetailsList .searchCarDetailsFeatureList {
    margin-top: 0px;
  }

  /* Booking unAthentication Manage Booking */
  .unAthentication .unAthenticationManageBooking {
    border-right: 1px solid #d4dee6;
  }

  /* Your Booking View Details */
  .BookingCarPhotos .carPhotosButton {
    border-radius: 12px;
    height: 46px;
    min-width: 150px;
    background-color: #ebebec;
    color: rgb(52, 58, 63);
  }

  .BookingCarPhotos .carPhotosButton input {
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    min-width: unset;
    min-height: unset;
  }

  .BookingCarPhotos .carPhotosButton:has(input:checked) {
    background: var(--primary) !important;
    color: #fff;
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical {
    overflow-x: scroll;
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical::-webkit-scrollbar {
    display: none;
  }

  .ViewDetailMobileTab {
    font-size: 12px;
    line-height: 16px;
    min-width: 135px;
    padding-bottom: 10px;
    border-bottom: 2px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(39, 39, 39, 0.5);
    font-size: 16px;
  }

  .yourBookingViewDetials .ViewDetailMobileTab input {
    width: 0;
    height: 0;
    opacity: 0;
    min-width: unset;
    min-height: unset;
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical label:has(input:checked) {
    border-bottom: 2px solid rgba(39, 39, 39, 1);
    color: rgba(39, 39, 39, 1);
  }

  .yourBookingViewDetials .ViewDetailsInfoVertical {
    position: relative;
  }

  /* Edit Car Photo Upload And Change */
  .EditRideCarPhoto .ant-modal-content {
    border-radius: 0;
    padding: 0;
    box-shadow: none;
  }

  .EditRideCarPhotoHeader {
    border-bottom: 1px solid #d9d9d9;
  }

  .EditRideCarPhoto .carPhotosButton {
    border-radius: 12px;
    height: 46px;
    min-width: 150px;
    background-color: #ebebec;
    color: rgb(52, 58, 63);
  }

  .EditRideCarPhoto .carPhotosButton input {
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    min-width: unset;
    min-height: unset;
  }

  .EditRideCarPhoto .carPhotosButton:has(input:checked) {
    background: var(--primary) !important;
    color: #fff;
  }

  .CustomerIndex .SearchCarDetails .customerSearchLocation {
    padding-top: 45px;
  }
}

@media screen and (min-width: 992px) {
  .SearchResult .SearchChangeDateImage {
    width: 40px;
    min-width: 40px;
  }

  /* Adjust Rental Processbar Responsive */
  .BookingIndex .Progressbar .bookingProcessbar {
    width: 66.66666667%;
  }

  /* Lost And Found */
  .LostAndFoundLocation {
    position: absolute;
    right: 0;
    top: 56px;
  }

  .SearchResult .ChangePickup img,
  .SearchResult .ChangeDropOff img {
    display: none !important;
  }

  /*  */
  .BookingIndex .adjustRental .bookingDividePickupDrop {
    margin-top: 3rem !important;
  }
}

@media screen and (min-width: 991px) {
  .AuthIndex {
    padding-top: calc(60px);
  }
}

@media screen and (min-width: 1400px) {
  .BookingConfirm
    .BookingConfirmDescription
    .bookingPikerLocation
    .overflowHidden {
    overflow: hidden;
  }

  .yourBookingRideList .CarImageSection {
    max-width: 275px;
    max-height: 275px;
  }

  .homePage .banner_section .banner-select-option {
    padding-bottom: calc(3rem + 1rem) !important;
  }
}
/* Min and Max */
@media (min-width: 320px) and (max-width: 425px) {
  .MeetSomeMoreCarBackground .SamplePrevArrow {
    left: calc(10%) !important;
  }

  .MeetSomeMoreCarBackground .SampleNextArrow {
    right: calc(10%) !important;
  }

  .MeetSomeMoreCarBackground img {
    max-height: 130px;
  }

  .carRentalProductCard .clippath {
    clip-path: polygon(0% 0%, 40% 0px, 70% 50%, 40% 100%, 0% 100%) !important;
  }

  .dealImageZIndex {
    max-width: 135px;
    transform: translate(-25%, -40%);
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .carRentalMostPopular .slick-prev {
    left: 15px;
  }

  .carRentalMostPopular .slick-next {
    right: 40px;
  }

  /* Booking Listing */
  .yourBookingRideList .searchCarImageSection,
  .yourBookingViewDetials .SearchFilterCard .searchCarImageSection {
    max-height: 150px;
    margin-left: auto;
  }

  .customerCarAvailable .carAvailableRadioBtn {
    position: relative;
    top: 0.12rem;
  }
}

@media (min-width: 425px) and (max-width: 459.98px) {
  .dealImageZIndex {
    transform: translate(-35%, -40%);
  }
}

@media (min-width: 470px) and (max-width: 575px) {
  .carRentalProductCard .clippath {
    clip-path: polygon(0% 0%, 60% 0px, 90% 50%, 60% 100%, 0% 100%) !important;
  }

  .dealImageZIndex {
    transform: translate(-35%, -40%);
  }
}

@media (min-width: 535px) and (max-width: 575px) {
  .dealImageZIndex {
    transform: translate(-45%, -40%);
  }
}

@media (min-width: 576px) and (max-width: 767.68px) {
  .carRentalProductCard .clippath {
    clip-path: polygon(0% 0%, 30% 0px, 60% 50%, 30% 100%, 0% 100%) !important;
  }

  .dealImageZIndex {
    max-width: 135px;
    transform: translate(-25%, -40%);
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .CustomerIndex .homePage .banner_section_background {
    padding-bottom: 40px;
    padding-top: 0;
  }

  .PickUpSlider .SamplePrevArrow {
    left: calc(47.5% - 20px) !important;
  }

  .PickUpSlider .SampleNextArrow {
    right: calc(47.5% - 20px) !important;
  }

  /* MeetSomeMoreCarBackground */
  .MeetSomeMoreCarBackground .slick-slide .f16,
  .MeetSomeMoreCarBackground .slick-slide .f20 {
    font-size: 14px;
    line-height: normal;
  }

  .MeetSomeMoreCarBackground .slick-slide .f14 {
    font-size: 11px;
    line-height: 14px;
  }

  .MeetSomeMoreCarBackground .slick-slide button.f14 {
    height: 40px;
  }

  .PromoBanner .PromoBannerImage {
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Pickup DropOfff */

  .PickUpSlider .PickupItem .PickupDescription p {
    font-size: 12px;
    line-height: 24px;
  }
  .PickUpSlider .PickupItem .PickupDescription button {
    font-size: 10px;
    line-height: 10px;
  }

  .AboutUsStyle .KnowCarRental {
    padding: 3rem 0 !important;
  }

  .AboutUsBoxContent h4 {
    font-size: 18px;
    line-height: 23px;
  }

  .AboutUsBoxContent p {
    font-size: 12px;
    line-height: 16px;
  }

  .AboutUsStyle h4,
  .KnowCarRental h4,
  .HowItWorks .HowItWorkListLabel,
  .customerFaqStyle h4,
  .OurLocation .ourLocationBanner h4,
  .CarLocationsTitle h4,
  .CareerPage .CareerPageBanner .bannerTitle,
  .CareerPage .employeeBenifits .employeeBenifitsTitle,
  .CareerPage .employeeBenifits .yourResumeTitle,
  .CareerPage .employeeBenifits .resume,
  .careerDirectMail h4,
  .RentalInformation .container > div > .f38,
  .LostAndFound .container > div > .f38,
  .LostAndFound .LostAndFoundCard .LostAndFoundHeading,
  .CarRental .CarRentalBanner .bannerTitle,
  .carRentalAndProduct > h4,
  .carRentalLocation > h4,
  .carRentalMostPopular > h4 {
    font-size: 22px;
    line-height: 29px;
  }

  .AboutUsStyle p,
  .KnowCarRental p,
  .customerFaqCard .customerFaqItems .ant-collapse-header-text,
  .customerFaqCard .customerFaqItems .ant-collapse-content-box,
  .OurLocation .ourLocationBanner p,
  .CarLocationsTitle p,
  .CarLocationsList .OurLocationCarList .CarModelType,
  .CareerPage .CareerPageBanner .bannerDescription,
  .CareerPage .employeeBenifits .personalInformation,
  .careerDirectMail a,
  .CareerPage .employeeBenifits .EmployeeBenifitsDescription,
  .RentalInformation .container > div > .f38Desc,
  .LostAndFound .container > div > .f38Desc,
  .RentalInformation .RentalInformationDescription p,
  .LostAndFound .LostAndFoundCard li,
  .CarRental .CarRentalBanner .bannerDescription,
  .carRentalLocation > p,
  .carRentalMostPopular > p {
    font-size: 14px;
    line-height: 18px;
  }

  .HelpItem .HelpItemHeading,
  .OurLocation .locationItemDescription .f24,
  .CarLocationsList .OurLocationCarList .CarName,
  .RentalInformation .RentalInformationTitle,
  .CarRental .carRentalProductImageDetail h4 {
    font-size: 18px;
    line-height: 23px;
  }

  .HelpItem .HelpItemHeading {
    min-height: 2rem;
  }

  .HowItWorkList h2 {
    font-size: 25px;
    line-height: 33px;
  }

  .HowItWorkList h4,
  .CarRental .MostPopularCarImageDesc h4 {
    font-size: 16px;
    line-height: 21px;
  }

  .HowItWorkList p,
  .customerHelp .HelpItemContent .helpArticleList p,
  .OurLocation .locationItemDescription .ff_rg,
  .CarRental .carRentalProductImageDetail p,
  .CarRental .carRentalLocation .carLocationCard p,
  .CustomerIndex .carRentalLocation .radioWithLabelActive label,
  .CarRental .MostPopularCarImageDesc p {
    font-size: 13px;
    line-height: 17px;
  }

  .OurLocation .locationItemDescription .h54,
  .careerDirectMail img,
  .employeeBenifits .h54,
  .CustomerIndex .carRentalLocation .radioWithLabelActive label {
    height: 48px;
  }

  .howitwork .circle {
    width: 45px;
    height: 45px;
  }

  .customerFaqCard .customerFaqItems .ant-collapse-content-box {
    padding: 5px 16px;
    padding-block: 5px !important;
    padding-left: 30px !important;
  }

  .customerFaqCard .customerFaqItems {
    padding: 1rem 0 !important;
  }

  .customerFaqCard .customerFaqItems .ant-collapse-expand-icon img {
    height: 18px !important;
    width: 18px !important;
  }

  .customerHelp .HelpItemContent .HelpItemVector svg {
    max-width: 30px;
    max-height: 30px;
    padding: 2.5px;
  }

  /* lost and found */
  .LostAndFound .LostAndFoundCard li {
    list-style: none;
  }

  /* Car Rental */
  .CarRental .carLocationCard {
    min-height: 75px;
  }

  .nmOfDriver .seatSquareButton {
    font-size: 12px;
  }
  .nmOfDriver .modelSubmitHandlerButton .ff_bd {
  }
}

@media screen and (min-width: 320px) and (max-width: 1400px) {
  /* Booking Confirm */
  .BookingConfirm .BookingConfirmDescription .bookingPikerLocation .drawline {
    position: relative;
  }

  .BookingConfirm
    .BookingConfirmDescription
    .bookingPikerLocation
    .drawline:before {
    content: "";
    width: 1.5px;
    height: calc(100% - 15px);
    display: block;
    position: absolute;
    background: rgba(52, 58, 63, 1);
    left: -16.5px;
    top: 23.5px;
  }
}

@media (min-width: 375px) and (max-width: 768px) {
  .MeetSomeMoreCarBackground .SamplePrevArrow {
    left: calc(15%) !important;
  }

  .MeetSomeMoreCarBackground .SampleNextArrow {
    right: calc(15%) !important;
  }

  .MeetSomeMoreCarBackground img {
    max-height: 170px;
    height: 100px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .CustomerIndex .SearchCarDetails .customerSearchLocation {
    padding-top: 0;
    padding-bottom: 70px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .yourBookingRideList .searchCarImageSection,
  .yourBookingViewDetials .SearchFilterCard .searchCarImageSection {
    max-height: 150px;
    margin-left: auto;
  }
}

@media screen and (min-width: 601px) and (max-width: 991px) {
  .SearchFilterCard .orderNumberTwo .mobile-d-none {
    display: none !important;
  }

  .SearchFilterCard .orderNumberTwo {
    order: -1;
  }

  .SearchFilterCard .orderNumberTwo > div {
    display: block !important;
  }

  .SearchFilterCard .VehicleInfo {
    text-align: start !important;
  }

  .SearchFilterCard .VehicleInfo ul span.f14 {
    font-size: 12px;
  }

  .SearchFilterCard .VehicleInfo p.f12 {
    font-size: 10px;
  }

  .SearchFilterCard .VehicleInfo p.f12 .f14 {
    font-size: 11px;
  }

  .SearchFilterCard .mobileCarPriceAndFeature {
    margin-top: -35px;
  }

  .SearchFilterCard .orderNumberTwo .mobile-d-none {
    display: none !important;
  }

  .SearchFilterCard .orderNumberTwo {
    order: -1;
  }

  .SearchFilterCard .orderNumberTwo > div {
    display: block !important;
  }

  .SearchFilterCard .VehicleInfo {
    text-align: start !important;
  }

  .SearchFilterCard .VehicleInfo ul span.f14 {
    font-size: 12px;
  }

  .SearchFilterCard .VehicleInfo p.f12 {
    font-size: 10px;
  }

  .SearchFilterCard .VehicleInfo p.f12 .f14 {
    font-size: 11px;
  }

  .SearchFilterCard .mobileCarPriceAndFeature {
    margin-top: -35px;
  }

  .SearchFilterCard .desktop-d-none {
    display: block !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* Booking Confirm */
  .BookingConfirm .BookingConfirmDescription .bookingPikerLocation {
    position: relative;
    /* left: 4rem; */
  }
  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .text-end
    > h4 {
    font-size: 14px;
    line-height: normal;
  }

  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .text-end
    > p {
    font-size: 11px;
    line-height: 15px;
  }

  .BookingConfirm
    .bookingConfirmCarDetails
    .BookingConfirmDescription
    .text-end
    > p
    span {
    font-size: 13px;
  }

  .BookingConfirm .BookingConfirmCar .carName {
    font-size: 15px;
    line-height: 20px;
  }

  .BookingConfirm .BookingConfirmCar .carType {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .MeetSomeMoreCarBackground .SamplePrevArrow {
    left: calc(25%) !important;
  }

  .MeetSomeMoreCarBackground .SampleNextArrow {
    right: calc(25%) !important;
  }

  .PickUpSlider .SamplePrevArrow {
    left: calc(47.5% - 20px) !important;
  }

  .PickUpSlider .SampleNextArrow {
    right: calc(47.5% - 20px) !important;
  }

  /* Home Page */
  .homePage .banner_section .banner_section_background {
    display: flex;
    align-items: center;
  }

  /* Display Location */
  .PickUpDrop .displayLocation .ant-steps-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Search */
  .searchFilterHeading button {
    font-size: 14px;
  }

  .CustomerIndex
    .searchFilter
    .CustomCheckBox
    .form-check-input[type="checkbox"] {
    border-radius: 50%;
    font-size: 18px;
    margin-top: 0;
  }

  .searchFilter .SquareButton {
    width: 30px;
    height: 30px;
    border-radius: 6px;
  }
}

@media screen and (max-width: 1400px) {
  /* Your Booking - Upcoming, Active, All */
  .BookingList .BookingRideType {
    overflow-x: scroll;
  }

  .BookingList .BookingRideType::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 991px) and (max-width: 1399.98px) {
  .homePage .banner_section .banner-select-option button {
    width: 100%;
  }

  .BookingList .BookingRideType > label {
    font-size: 14px;
    line-height: 18px;
  }

  .homePage .banner_section .banner-select-option {
    padding-bottom: calc(3rem + 1.5rem) !important;
  }
}

@media screen and (min-width: 991px) and (max-width: 1199.98px) {
  .SearchFilterCard .heightForCarDetailFeature {
    height: calc(100% - 68px);
  }
}

@media screen and (min-width: 1200px) {
  .SearchFilterCard .heightForCarDetailFeature {
    height: calc(100% - 50px);
  }
}

@media (min-width: 991px) and (max-width: 1023.98px) {
  .carRentalProductCard .clippath {
    clip-path: polygon(0% 0%, 60% 0px, 90% 50%, 60% 100%, 0% 100%) !important;
  }

  .dealImageZIndex {
    transform: translate(-35%, -40%);
  }
}

@media (min-width: 1024px) and (max-width: 1399.98px) {
  .carRentalProductCard .clippath {
    clip-path: polygon(0% 0%, 30% 0px, 60% 50%, 30% 100%, 0% 100%) !important;
  }

  .dealImageZIndex {
    max-width: 135px;
    transform: translate(-25%, -40%);
  }
}
