/* Profile - Loader */
.adminLoader {
  display: grid;
  justify-content: space-around;
  align-content: stretch;
  justify-items: center;
  min-width: 200px;
  min-height: 200px;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  margin: 0 auto;
}

.adminLoader > div {
  align-self: last baseline;
}

/* Dashboard */
.Dashboard .MostPopularBooking > * {
  pointer-events: none;
}

.AdminIndex .Views .Dashboard .PageSection {
  margin: 0;
}

.AdminIndex .Views .Dashboard .PageSection .PageTitle {
  margin: 2rem 0;
}

.AdminIndex .Views .Dashboard .PageSection .StatCard .Title {
  text-transform: capitalize;
}

.AdminIndex .Views .Dashboard .dashboardRecentCarBooking .InfoCard {
  margin-right: 0;
  margin-bottom: 0;
}

.AdminIndex .Views .Dashboard .dashboardRecentCarBooking .InfoCard .bookingAdminAvatar,
.ProfilePicture {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

/* Booking - No Transaction */
.noTransaction {
  max-width: 65px;
}

/* Booking Model View in Tablet and Mobile Start */
.ant-modal-root .ant-modal-wrap {
  overflow: unset;
}

.BookingViewModal {
  top: 0;
  margin: 0;
  max-width: 100%;
  border-radius: 0;
  padding: 0;
}

.BookingViewModal .ant-modal-content {
  padding: 0;
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.BookingViewModal .selectBookingTitle {
  font-size: 22px;
  line-height: 29px;
  margin-bottom: 0.25px;
}

.BookingViewModal .selectedBookingCardImage img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
  height: 360px;
}

.BookingViewModal .TabContainer::-webkit-scrollbar,
.BookingViewModal .uploadCarPhotos::-webkit-scrollbar {
  display: none;
}

.BookingViewModal .TabContainer {
  position: relative;
}

.BookingViewModal .TabContainer,
.BookingViewModal .uploadCarPhotos {
  position: relative;
  overflow-x: scroll;
}

.BookingViewModal .TabContainer .TabChildren {
  line-height: 16px;

  padding-bottom: 10px;
  border-bottom: 2px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(39, 39, 39, 0.5);
  font-size: 16px;
}

.BookingViewModal .TabContainer .TabChildren input {
  width: 0;
  height: 0;
  opacity: 0;
  min-width: unset;
  min-height: unset;
}

.BookingViewModal .TabContainer .TabChildren:has(input:checked) {
  border-bottom: 2px solid var(--dark);
  color: var(--dark);
  z-index: 1;
}

.BookingViewModal .IndivisualAndBusinessDetails p.ff_rg {
  font-size: 12px;
  line-height: 16px;
}

.BookingViewModal .IndivisualAndBusinessDetails p.ff_md {
  font-size: 13px;
  line-height: 17px;
}

.BookingViewModal .PickUpDrop .displayLocation .ant-steps-item-title {
  font-size: 14px;
  line-height: 18px;
}

.BookingViewModal .bookingPriceTitle,
.BookingViewModal .priceSpecificationHeading {
  font-size: 20px;
  line-height: 26px;
}

.BookingViewModal .BookingPriceLabel {
  font-size: 14px;
  line-height: 18px;
}

.BookingViewModal .BookingPriceDescription {
  font-size: 12px;
  line-height: 16px;
}

.BookingViewModal .BookingPriceCost {
  font-size: 14px;
  line-height: 18px;
}

.BookingViewModal .selectButtonOfPay {
  display: block !important;
}

.BookingViewModal .selectCarPhoto .selectCarPhotoHeader h4,
.BookingViewModal .BookingRideProcess > p {
  font-size: 17px;
  line-height: 30px;
}

.BookingViewModal .BookingRideProcess > p {
  margin-bottom: 0.5rem !important;
}

.BookingViewModal .selectCarPhoto .radioWithLabelActive label:has(input:checked) {
  background: var(--primary) !important;
  color: var(--light);
}

.BookingViewModal .selectCarPhoto .radioWithLabelActive input {
  width: 0;
  height: 0;
  opacity: 0;
}

.BookingViewModal .selectCarFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #fff;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.BookingViewModal .mobileUpcoming {
  padding-bottom: 7rem;
}

.BookingViewModal .mobileReview > h4 {
  font-size: 17px;
  line-height: 30px;
}

.BookingViewModal .mobileReview .reviewProfile img {
  width: 44px;
  height: 44px;
}

.BookingViewModal .mobileReview .reviewProfile span {
  font-size: 14px;
  line-height: 18px;
}

.BookingViewModal .mobileReview .reviewRate ul {
  font-size: 12px !important;
  margin-bottom: 2px;
}

.BookingViewModal .mobileReview .reviewRating img {
  width: 16px;
}

.BookingViewModal .mobileReview .reviewRating span {
  font-size: 11px;
  line-height: 14px;
}

.BookingViewModal .mobileReview .reviewDescription {
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
}

.adminVerifyDocument img,
.adminBookingCarPhotoListDesc img {
  object-fit: cover;
  aspect-ratio: 1 /1;
}

.BookingViewModal .form-check-input[type="radio"] {
  width: 0;
  height: 0;
  visibility: hidden;
}
/* Booking Model View in Tablet and Mobile End */

/* Dashboard Most Popular Booking */
.Dashboard .dashBoardMPB,
.AdminIndex .Dashboard .dashboardRecentCarBooking .PageSectionContainer {
  display: flex;
  overflow-x: scroll;
  gap: 1rem;
}

.Dashboard .dashBoardMPB .MostPopularBooking,
.AdminIndex .Dashboard .dashboardRecentCarBooking .PageSectionContainer > div {
  min-width: 20%;
  margin-bottom: 10px;
}

.Dashboard .dashBoardMPB::-webkit-scrollbar,
.AdminIndex .Dashboard .dashboardRecentCarBooking .PageSectionContainer::-webkit-scrollbar {
  height: 5px;
}

.Dashboard .dashBoardMPB::-webkit-scrollbar-thumb,
.AdminIndex .Dashboard .dashboardRecentCarBooking .PageSectionContainer::-webkit-scrollbar-thumb {
  background-color: rgba(14, 52, 75, 0.5);
}

.AdminIndex .Views .Dashboard .PageSection.dashBoardBookingManagement .PageTitle {
  margin-bottom: 0;
}

/* Dashboard Most Popular Booking End */

.AdminIndex .adminTimelineStyle .ant-timeline-item-head {
  border-width: 1px;
}

.AdminIndex .adminTimelineStyle .ant-timeline-item-tail {
  border-inline-start: 1px solid rgba(5, 5, 5, 0.5);
}

.AdminIndex .adminTimelineStyle .ant-timeline-item-content {
  font-size: 12px !important;
  font-family: "ff_rg";
  position: relative;
  top: -3px;
}
/* Employee */

.AdminIndex .locationCardItem > div,
.AdminIndex .employeeCardItem > div {
  padding: 0.85rem;
}

.AdminIndex .employeeProfile .employeeAvatar img,
.AdminIndex .locationCardItem .locationAvatar img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  object-fit: contain;
}

.AdminIndex .employeeProfile .employeeName {
  font-size: 16px;
  line-height: 22px;
}

.AdminIndex .employeeProfile .employeeId {
  font-size: 11px;
  line-height: 14px;
}

.AdminIndex .employeeProfileInfo .employeeDetail {
  font-size: 14px;
  line-height: 18px;
}

.AdminIndex .employeeProfileInfo .employeeDetail img {
  height: 16px;
  width: 16px;
}

.AdminIndex .employeeCardItem .ToggleButton,
.AdminIndex .locationCardItem .ToggleButton,
.AdminIndex .staticPageFAQCard .ToggleButton,
.ToggleStyle .ToggleButton {
  position: absolute;
  right: 1.2rem;
  top: 0.5rem;
  text-align: end;
  width: auto;
  padding: 0;
  margin: 0;
}

.AdminIndex .employeeCardItem .ToggleButton button,
.AdminIndex .locationCardItem .ToggleButton button,
.AdminIndex .staticPageFAQCard .ToggleButton button,
.ToggleStyle .ToggleButton button {
  background-color: transparent;
}

.AdminIndex .employeeCardItem .ToggleButton .ToggleBGlist,
.AdminIndex .locationCardItem .ToggleButton .ToggleBGlist,
.AdminIndex .staticPageFAQCard .ToggleButton .ToggleBGlist,
.ToggleStyle .ToggleButton .ToggleBGlist {
  background: var(--light-md);
  border-radius: 12px;
  text-align: start;
  min-width: 150px;
  display: none;
  transition: all 0.25s ease;
  color: var(--light);
}

.AdminIndex .employeeCardItem .ToggleButton .ToggleBGlist ul li,
.AdminIndex .locationCardItem .ToggleButton .ToggleBGlist ul li,
.AdminIndex .staticPageFAQCard .ToggleButton .ToggleBGlist ul li,
.ToggleStyle .ToggleButton .ToggleBGlist ul li {
  font-family: "ff_md";
  font-size: 12px;
  line-height: 2rem;
  list-style: none;
  cursor: pointer;
}

.AdminIndex .employeeCardItem .ToggleButton .ToggleBGlist.is-active,
.AdminIndex .locationCardItem .ToggleButton .ToggleBGlist.is-active,
.AdminIndex .staticPageFAQCard .ToggleButton .ToggleBGlist.is-active,
.ToggleStyle .ToggleButton .ToggleBGlist.is-active {
  display: block;
  z-index: 9;
  position: relative;
}

/* location */
.AdminIndex .Location .locationCardItem {
  min-height: 115px;
}

/* Manage Car */
.AdminIndex .Views {
  overflow: hidden;
}

.AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderLeft .CarCategoryList > div::-webkit-scrollbar {
  display: none;
}

/* Add & Edit Car */
.AdminIndex .AddCarPage .ChooseBrand .brand-list {
  overflow-x: scroll;
  column-gap: 0.5rem;
}

.AdminIndex .AddCarPage .ChooseBrand .brand-list::-webkit-scrollbar {
  display: none;
}

.AdminIndex .AddCarPage .ChooseBrand .brand-list .form-check-input[type="radio"] {
  min-width: 1rem;
  min-height: 1rem;
}

.AdminIndex .AddCarPage .ChooseBrand .brand-list .swiper-slide,
.AdminIndex .myAddCar .swiper-slide {
  width: max-content;
}

.AdminIndex .mySwiper .swiper-button-next,
.AdminIndex .mySwiper .swiper-button-prev {
  height: 26px;
  width: 26px;
  background-color: var(--secondary);
  border-radius: 50%;
  top: 75%;
}

.mySwiper .swiper-button-next:after,
.mySwiper .swiper-button-prev:after {
  font-size: 12px;
  color: #fff;
}

/* Static Page Model */
.modelStaticPage .InputBox #container,
.modelStaticPage .InputBox input,
.adminUpdateStaticPage .InputBox input {
  height: 100%;
  min-height: 50px;
  background-color: var(--primary-opacity);
}

.modelStaticPage .InputBox input:focus {
  border: 1px solid var(--dark);
}

.modelStaticPage .ant-modal-body .text-center {
  font-size: 24px;
  line-height: 24px;
  font-family: "ff_bd";
}

.staticPageDropdown .pageDropDownData,
.modelStaticPage .HelpArticleModelData {
  display: none;
}

.staticPageDropdown .pageDropDownData.is-active,
.modelStaticPage .HelpArticleModelData.is-active {
  display: block;
  position: absolute;
  right: 0;
  min-width: 125px;
  z-index: 11;
  width: 100%;
}

.staticPageDropdown .pageDropDownData input,
.modelStaticPage .HelpArticleModelData input {
  visibility: hidden;
  width: 0;
  height: 0;
  line-height: 0;
  padding: 0;
}

.AdminIndex .StaticPage .staticPageHelpCategory .dataIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px;
}

/* Admin Profile */
.AdminIndex .adminProfileUpload .ant-upload-list {
  display: none;
}

.AdminIndex .adminProfile .error-message {
  margin-top: 0.25rem;
}

/* Min Height */
/* .AdminIndex .Dashboard, */
.AdminIndex .Employee,
.AdminIndex .Customer,
.AdminIndex .Cars,
.AdminIndex .Location,
.AdminIndex .Promocode,
.AdminIndex .Earning,
.AdminIndex .StaticPage,
.AdminIndex .EmailTemplate,
.AdminIndex .Setting,
.AdminIndex .Profile,
.AdminIndex .AddCarPage,
.AdminIndex .manageCarDetail {
  min-height: 100vh;
}

.AdminIndex .Booking {
  min-height: calc(100vh - 70px) !important;
}

/* Image */
.Dashboard .dashBoardMPB .MostPopularBooking .mostPopularBookingImage {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.Cars .ShowAllCarList .ListOfCar > .listOfCarStyle .manageCarImage img {
  width: 110px;
  height: 110px;
  object-fit: contain;
}

/* Car Edit Image */
.carEditPhotosModel {
  width: 100% !important;
  max-width: 1024px;
}

.carEditPhotosUpload .car-image-upload-grid .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
}

.carEditPhotosUpload .car-image-upload-grid .ant-upload button,
.carEditPhotosUpload .car-image-upload-grid .ant-upload {
  height: 100% !important;
  width: 100% !important;
  display: block !important;
}

.carEditPhotosUpload .car-image-upload-grid .ant-upload-list .ant-upload-list-item-container {
  width: 100% !important;
  display: block !important;
  border: none !important;
}

.carEditPhotosUpload .car-image-upload-grid .ant-upload button,
.carEditPhotosUpload .car-image-upload-grid .ant-upload img,
.carEditPhotosUpload .car-image-upload-grid .ant-upload-list .ant-upload-list-item-container {
  min-height: 200px;
  border: 1px solid var(--primary-opacity);
}

.carEditPhotosUpload .CustomerInput textarea.form-control {
  min-height: 100px;
}

.carEditPhotosUpload .car-image-upload-grid .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item .ant-upload-list-item-thumbnail .ant-upload-list-item-image {
  object-fit: cover !important;
}

.carEditPhotosUpload .car-image-upload-grid .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item .ant-upload-list-item-actions > a {
  display: none;
}

.ant-modal-close-x {
  background-image: url(../assets/CloseIcon.svg);
  width: 30px;
  height: 30px;
  display: grid !important;
  align-items: center;
  /* color: #fff; */
  background-size: cover;
  color: #c99d4c;
  border-radius: 50%;
}
/* .ant-modal-close-x:hover {
  color: #c99d4c;
} */

.carEditPhotosUpload .car-image-upload-grid .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item {
  border: 1px solid rgba(189, 189, 189, 1);
  border-radius: 12px;
}

.carEditPhotosUpload .car-image-upload-grid .ant-upload.ant-upload-select {
  border: none !important;
}

.carEditPhotosModel .sigCanvas {
  width: 100%;
  max-width: 400px;
  max-height: 150px;
  min-height: 150px;
  margin-left: auto;
}

.carEditPhotosModel .sigCanvas .sigCanvasEditor {
  width: 100%;
  height: 100%;
  max-height: 150px;
  min-height: 150px;
}

.carEditPhotosModel .signatureRedo {
  right: 1rem;
  top: 2.5rem;
}

.Booking .CarPhotosList .carPhotoImage img,
.BookingViewModal .CarPhotosList .carPhotoImage img,
.yourBookingViewDetials .CarPhotosList .carPhotoImage img {
  aspect-ratio: 1/1;
  object-fit: contain;
}

.AdminIndex .Booking .carPhotoDescription .setMinHeight {
  min-height: 80px;
  overflow-y: scroll;
  max-height: 80px;
}

.adminReceivePayment .ReceiveFrom label:has(input:checked) {
  background: var(--primary) !important;
}

.adminReceivePayment .ReceiveFrom input[type="radio"] {
  accent-color: var(--light);
  color: var(--light);
}

.adminReceivePayment .ReceiveFrom label:has(input:checked) .ms-1 {
  color: var(--light);
}

.accent-color-primary {
  accent-color: var(--primary);
}

/* Admin Side Review */

.adminModelReview {
  width: 100% !important;
}

.adminModelReview .ant-modal-content {
  border-radius: 0;
  padding: 0;
}

.adminModelReview .WriteAReview > div {
  min-height: 90px;
}

.adminModelReview .searchCarImageSection {
  aspect-ratio: 1/1;
}

.adminModelReview .WriteAReview .WriteAReviewCarInfo img {
  height: 140px;
  width: 140px;
  object-fit: contain;
}

.adminModelReview .WriteAReview .customerReviewList:last-child {
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.adminModelReview .RadioBox input:checked + label {
  color: var(--light) !important;
}

.PaymentReceivedThrough {
  gap: 0.5rem;
  overflow-x: scroll;
}

.PaymentReceivedThrough::-webkit-scrollbar {
  display: none;
}

.refundDeposit .PaymentReceivedThrough > label[for="In-Cashrefund"],
.ReceiveSecurityDeposit .PaymentReceivedThrough > label[for="In-CashPayment Received Through"],
.adminReceivePayment .PaymentReceivedThrough > label[for="In-CashadminReceivePayment"] {
  min-width: 110px;
}

.Booking p + p,
.BookingViewModal p + p {
  margin-top: 0;
}

.EmailTemplate .NotificationTemplateInformation {
  position: absolute;
  right: 40px;
  top: 0;
  z-index: 3;
  display: none;
  transition: ease 0.25s all;
}

.EmailTemplate .NotitficationTemplateIcon:hover + .NotificationTemplateInformation {
  display: block;
}

.EmailTemplate .NotificationTemplateDescription {
  max-width: 320px;
  min-width: 320px;
}

.ant-pagination .ant-pagination-item {
  background-color: var(--primary-opacity);
  color: var(--dark);
  border-color: var(--primary-opacity);
}

.ant-pagination .ant-pagination-item-active {
  background-color: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.ant-pagination .ant-pagination-next .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emailTemplateTable .ant-table-pagination.ant-pagination,
.AdminIndex .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.emailTemplateTable .ant-table-thead > tr > th {
  color: var(--primary);
}

.carEditPhotosModel .h40 {
  height: 34px;
}

.AddCar .carLocaionListLable.is-active,
.carListUpdateLocation .carLocaionListLable.is-active,
.Booking .CarInputBox .carExteriorColorData .carExteriorColorLabel.is-active {
  color: var(--secondary);
}

.AddCar .carLocaionListLable.is-active svg *,
.carListUpdateLocation .carLocaionListLable.is-active svg * {
  fill: var(--secondary);
}

.adminReceivePayment .CustomerInput .form-control {
  height: 44px;
}

.CustomerInput input::-webkit-outer-spin-button,
.CustomerInput input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.AdminIndex .adminProfile .CustomerInput label {
  color: var(--light-md);
}

.AdminIndex .Profile .adminProfile .CustomerInput:has(input:focus) label {
  color: var(--dark);
}

.AdminIndex .NotificationCard {
  position: relative;
}

.addPickupLocation .ant-modal-body .InputBox .text-danger,
.adminCarPromocode .InputBox .text-danger,
.adminFAQModel .InputBox .text-danger,
.adminHelpCategory .InputBox .text-danger,
.adminEmployeeModel .InputBox .text-danger,
.adminEmailTemplateTitle .error-message {
  padding-top: 5px !important;
}

.AdminIndex .Booking .bookingSearchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminIndex .Booking .CarInputBox .carExteriorColor,
.AdminIndex .Booking .bookingSearchBar {
  height: 40px;
}

.AdminIndex .Booking .CarInputBox .carExteriorColor {
  border-radius: 6px;
}

.AdminIndex .Booking .CarInputBox .carExteriorColor .carExteriorColorSetHexa {
  height: 20px;
  width: 20px;
}

.Setting .GeneralItemsAdd .user-select-none {
  border-color: var(--primary);
}

.manageReason .GeneralItemsAddSpace {
  margin-bottom: 30px !important;
}

.AdminIndex .AddCar .addCarInDatePicker input {
  padding-right: 1rem;
}

.AdminIndex .AddCar .addCarInDatePicker .ant-picker .ant-picker-clear {
  right: 1rem;
}

.ant-image-preview-switch-right,
.ant-image-preview-switch-right:hover,
.ant-image-preview-switch-left,
.ant-image-preview-switch-left:hover {
  background-color: #fff;
}

.ant-image-preview-switch-right svg *,
.ant-image-preview-switch-left svg * {
  fill: var(--primary) !important;
}

.ant-image-preview-switch-right.ant-image-preview-switch-right-disabled,
.ant-image-preview-switch-left.ant-image-preview-switch-left-disabled {
  opacity: 0.5;
}

.AdminIndex .emailTemplateTable .ant-table-thead > tr > th,
.AdminIndex .staticPageTable .ant-table-thead > tr > th {
  border-bottom: 1px solid var(--primary);
}

.AdminIndex .emailTemplateTable .ant-table-content table,
.AdminIndex .staticPageTable .ant-table-content table {
  border: 1px solid var(--primary);
}

.AdminIndex .emailTemplateTable .ant-table-tbody > tr > td,
.AdminIndex .staticPageTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--primary-opacity);
}

.helpCategoryCKEditor .ck-editor,
.adminEmailTemplateUpdate .ck-editor,
.adminUpdateStaticPage .ck-editor,
.adminAntModal .ck-editor {
  width: 100% !important;
}

.helpCategoryCKEditor .ck-editor__main .ck-editor__editable {
  min-height: 100px;
}

.ant-modal .ant-modal-close:hover {
  background-color: transparent !important;
}

.ViewNotificationTemplateModal .notificationTime {
  width: auto !important;
  right: 0;
  top: 10px;
}

.Setting .settingColorPicker {
  height: 48px;
  width: 48px;
}

.ant-color-picker-trigger .ant-color-picker-color-block {
  width: 32px;
  height: 32px;
}

.adminEmailTemplateUpdate .ant-modal-content,
.adminViewEmailTemplate .ant-modal-content {
  max-height: 700px;
  overflow-y: scroll;
  padding-bottom: 0;
}

.AdminIndex .Setting .GeneralItemsAdd .text-danger {
  position: absolute;
  bottom: -14px;
}

/* Upload in admin */
.adminBookingUploadDoc .customerBookingConfirmDocument {
  display: flex;
}
.adminBookingUploadDoc .customerBookingConfirmDocument:after,
.adminBookingUploadDoc .customerBookingConfirmDocument:before {
  display: none;
}

.AdminIndex .Views .PageSectionContainer .StatCard[data-titlename="active"] {
  order: -1;
}
.AdminIndex .Views .PageSectionContainer .StatCard[data-titlename="upcoming"] {
  order: 0;
}
.AdminIndex .Views .PageSectionContainer .StatCard[data-titlename="request"] {
  order: 1;
}
.AdminIndex .Views .PageSectionContainer .StatCard[data-titlename="cancelled"] {
  order: 2;
}
.AdminIndex .Views .PageSectionContainer .StatCard[data-titlename="completed"] {
  order: 3;
}
.AdminIndex .Views .PageSectionContainer .StatCard[data-titlename="expired"] {
  order: 4;
}
.carEditPhotosModel .ant-modal-content {
  max-height: 70vh;
  overflow-y: scroll;
}

.Booking .adminBookingRideList::-webkit-scrollbar {
  display: none !important;
}

.Booking .adminBookingRideList .minWidth175 {
  min-width: fit-content;
}

.adminDashboardItems .StatCard > div,
.dashBoardBookingManagement .StatCard > div {
  min-height: 84px !important;
}

.AdminIndex .adminNotification {
  padding-top: 80px;
}

.ck.ck-editor__editable_inline::-webkit-scrollbar {
  display: none;
}

.addEmployeeModal .ant-modal-body .InputBox {
  margin-top: 0;
}

.zipped-div > button {
  margin-left: -1rem !important;
  width: calc(100% + 2rem) !important;
}

.remove-img-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.AdminIndex .ant-table-content::-webkit-scrollbar {
  display: none;
}

.AdminIndex .Dashboard,
.AdminIndex .Setting,
.AdminIndex .EmailTemplate,
.AdminIndex .StaticPage,
.AdminIndex .Earning,
.AdminIndex .Promocode,
.AdminIndex .Location,
.AdminIndex .Cars,
.AdminIndex .manageCarDetail,
.AdminIndex .AddCarPage,
.AdminIndex .Customer,
.AdminIndex .Employee,
.AdminIndex .Booking,
.AdminIndex .Profile {
  padding-top: 70px;
}

.drivingIdentification .row .col-12 .ant-image {
  width: 100%;
}

.addPickupLocation .form-input-type {
  position: relative;
}

.addPickupLocation .locationClearIcon {
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease;
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%) scale(0.8);
}

.addPickupLocation .locationClearIcon.active {
  opacity: 1;
}

button[disabled="disabled"],
button:disabled {
  cursor: not-allowed;
}

#gmap_canvas {
  /* -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%); */
  width: 100%;
  min-height: 250px;
}

input:placeholder-shown,
.searchForaPlace input:placeholder-shown {
  text-overflow: ellipsis;
}

.CustomerInput .searchForaPlace input {
  width: calc(100% - 20px);
  background-color: transparent !important;
  border: 0;
  text-overflow: ellipsis;
}

.CustomerInput .searchForaPlace input:focus {
  border: transparent;
}

.CustomerIndex .profileBirthDay.is-filled .ant-picker-clear {
  opacity: 1;
}

.adminNotificationBody.fixedMaxHeight,
.customerNotificationBody.fixedMaxHeight {
  max-height: unset !important;
}

.CancelRidePopUp .error-message {
  margin-top: 4px;
}

.ant-pagination-options * {
  font-family: "ff_rg";
  font-size: 13px;
}

/* .deletedApplicationList thead tr th:first-child,
.deletedApplicationList tbody tr td:first-child {
  display: none;
}

.deletedApplicationList thead tr th:nth-child(2) {
  border-start-start-radius: 8px;
} */

.deletedPreviewListJobApplication {
  top: -36px;
  z-index: 9;
  position: relative;
}

@media screen and (min-width: 768px) {
  /* Dashboard */
  .AdminIndex .Dashboard .DashboardHeader .dashBoardTitle {
    text-align: start !important;
  }

  /* Seeting */
  .Setting .addColorButton {
    max-width: 125px;
  }
}

@media screen and (min-width: 769px) {
  .AdminIndex .NotificationCard.not-read > .d-flex:before,
  .CustomerIndex .NotificationCard.not-read > .d-flex:before {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    background-color: var(--secondary);
    border-radius: 50%;
    border: 1px solid var(--primary-opacity);
    top: 40%;
    left: 30px;
    transform: translate(-275%, -50%);
  }
}

@media screen and (min-width: 991px) {
  .AdminIndex .NavigationBar {
    top: calc(60px + 1.5rem);
  }

  /* Booking */
  .AdminIndex .bookingListingCard {
    overflow-y: scroll;
  }

  .AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderLeft {
    max-width: 70%;
  }
  .AdminIndex .NotificationCard.not-read > .d-flex:before,
  .CustomerIndex .NotificationCard.not-read > .d-flex:before {
    top: 50%;
    left: 30px;
  }
}

@media screen and (min-width: 991.98px) {
  .custom-booking-scroll .bookingListingCard,
  .custom-booking-scroll .selectedBookingCard {
    overflow-y: auto !important;
    max-height: calc(100vh - 200px) !important;
    min-height: calc(100vh - 200px) !important;
  }

  .increaseHeight.custom-booking-scroll.searchBookingList .bookingListingCard,
  .increaseHeight.custom-booking-scroll.searchBookingList .selectedBookingCard {
    max-height: calc(100vh - 160px) !important;
    min-height: calc(100vh - 160px) !important;
  }
}

@media screen and (max-width: 425px) {
  .adminAntModal .newBookingIDMax {
    display: block !important;
  }
}

@media screen and (max-width: 576px) {
  .carEditPhotosModel .startRideBUtton {
    width: 100%;
  }

  .AdminIndex .Promocode .PromoCodeCard .minWidth100 {
    max-width: 100px;
    min-width: 80px;
  }

  .customerCarReview.modelMaxHeight .ant-modal-content {
    max-height: unset;
    overflow-y: unset;
    height: 100%;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
  }

  .customerCarReview.modelMaxHeight .ant-modal-body {
    height: 100%;
  }

  .customerCarReview.modelMaxHeight .ant-modal-body .reviewCloseButton {
    position: fixed;
    width: 100%;
    bottom: 25px;
    right: 0;
  }
}

@media screen and (max-width: 767.98px) {
  /* Setting */
  .Setting .manageReason .GeneralItemsAddSpace {
    display: block !important;
  }

  .Setting .manageReason .GeneralItemsAddSpace .pageTitle {
    margin-bottom: 10px !important;
    text-align: center;
  }

  .AdminIndex .Setting {
    padding-bottom: 0 !important;
  }

  /* HeightUpdate */
  .AdminIndex .Promocode .createPromoCodeButton {
    height: 48px;
  }

  .AdminIndex .AddCarPage .AddCar .FormSubmitButton.carSubmitButton {
    width: 100% !important;
    max-width: unset;
  }

  .AdminIndex .AddCar .carSubmitButton {
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 768px) {
  /* Dashboard */
  .AdminIndex .Dashboard .DashboardHeader .dashBoardTitle {
    text-align: center;
    font-size: 22px;
    line-height: 29px;
  }

  .AdminIndex .Views .Dashboard .PageSection .PageTitle {
    font-size: 16px;
    line-height: 21px;
  }

  .AdminIndex .Views .Dashboard .dashboardRecentCarBooking .InfoCard {
    margin-bottom: 1rem;
  }

  .AdminIndex .Views .Dashboard .dashboardRecentCarBooking .row > div:last-child .InfoCard {
    margin-bottom: 0;
  }

  /* Manage Car */
  .AdminIndex .manageCarDetail .PageHeading {
    font-size: 20px;
    line-height: 26px;
  }

  .AdminIndex .manageCarDetail .ViewDetail .CarSpecification {
    padding: 0.75rem 0.75rem;
  }

  .AdminIndex .manageCarDetail .ViewDetail .CarSpecification .CarSpecificationTitle {
    font-size: 16px;
    line-height: 21px;
  }

  .AdminIndex .manageCarDetail .ViewDetail .CarSpecification .CarSpecList h4 {
    font-size: 12px;
    line-height: 16px;
  }

  .AdminIndex .manageCarDetail .ViewDetail .ViewDetails-Features img {
    width: 14px;
  }

  .AdminIndex .manageCarDetail .ViewDetail .CarSpecification .CarSpecList p,
  .AdminIndex .manageCarDetail .ViewDetail .ViewDetails-Features p,
  .AdminIndex .manageCarDetail .ViewDetail .CarDescription .viewDetailscarDescription,
  .AdminIndex .manageCarDetail .ViewDetail .extraDetailsBackground .firstTitle,
  .AdminIndex .manageCarDetail .ViewDetail .extraDetailsBackground .firstDescription {
    font-size: 14px;
    line-height: 18px;
  }

  .AdminIndex .Cars .ShowAllCarList .ListOfCar .ToggleButton {
    right: 0.75rem;
  }

  /* Setting */
  .Setting .h50 {
    height: 44px;
  }

  /* CarEdit */
  .carEditPhotosModel {
    top: 0;
    height: 100%;
  }

  .carEditPhotosModel .ant-modal-content {
    border-radius: 0 !important;
    max-height: 100% !important;
  }

  .carEditPhotosModel .sigCanvas {
    margin: 0 auto;
  }

  .carEditPhotosModel .ant-modal-content .GapOne {
    gap: 0.5rem;
  }

  .carEditPhotosModel .ant-modal-content [type="checkbox"] {
    min-width: 22px;
  }

  .AdminIndex .NotificationCard.not-read > .d-flex:before,
  .CustomerIndex .NotificationCard.not-read > .d-flex:before {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    background-color: var(--secondary);
    border-radius: 50%;
    border: 1px solid var(--primary-opacity);
    right: 10px;
    top: 10px;
  }

  .AdminIndex .NotificationCard .notificationTime,
  .CustomerIndex .NotificationCard .notificationTime,
  .ViewNotificationTemplateModal .notificationTime {
    order: -1;
  }

  .carListUpdateLocation .CarInputBox .carLocaionList.is-active {
    max-height: 200px !important;
  }

  .AdminIndex .Location .radioWithLabelActive label {
    min-width: 150px;
    font-size: 12px;
  }

  .deletedPreviewListJobApplication {
    margin-top: 1.5rem;
    text-align: center;
  }

  .emailTemplateTable.applicationList .ant-table-pagination.ant-pagination {
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  /* Dashboard */
  .AdminIndex .NavigationBar {
    display: none;
  }

  .AdminIndex .container-fluid.Views {
    margin-left: 0 !important;
  }

  /* Booking */

  .BookingViewModal .mobileActive {
    padding-bottom: 160px;
  }

  .BookingViewModal .mobileUpcoming {
    padding-bottom: 60px;
  }

  .BookingViewModal .mobileCancelled,
  .BookingViewModal .mobileCompleted {
    padding-bottom: 0;
  }

  .AdminIndex .Booking .selectedBookingCard {
    display: none !important;
  }

  .bookingListingCard .BookingId,
  .bookingListingCard .BookingListCardName {
    font-size: 14px;
    line-height: 18px;
  }

  .bookingListingCard .BookingListCardType,
  .bookingListingCard .BookingIdactive,
  .bookingListingCard .BookingIdcompleted,
  .bookingListingCard .BookingIdcancelled,
  .bookingListingCard .BookingIdNotBroughtback {
    font-size: 10px;
    line-height: 15px;
  }

  .bookingListingCard .BookingListCardCall img {
    width: 24px;
    height: 24px;
  }

  .bookingListingCard .BookingListCardEarned,
  .bookingListingCard .BookingListCardAmount {
    font-size: 13px;
    line-height: 17px;
  }

  .bookingListingCard .BookingListCardPickUP,
  .bookingListingCard .BookingListCardDropOff {
    font-size: 11px;
    line-height: 14px;
  }

  /* Employee */
  .AdminIndex .Employee .EmployeeHeader .EmployeeHeaderLeft,
  .AdminIndex .Customer .CustomerHeader .CustomerHeaderLeft,
  .AdminIndex .Location .LocationHeader .LocationHeaderLeft,
  .AdminIndex .adminNotification .ProfileHeader .ProfileHeaderLeft,
  .AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderLeft,
  .AdminIndex .AddCarPage .manageAddCarHeader .manageAddCarHeaderLeft,
  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderLeft,
  .AdminIndex .Profile .ProfileHeader .ProfileHeaderLeft,
  .AdminIndex .Promocode .Promocodeheader .PromocodeHeaderLeft,
  .AdminIndex .StaticPage .StaticPageHeader .StaticPageHeaderLeft,
  .AdminIndex .EmailTemplate .EmailTemplateHeader .EmailTemplateHeaderLeft {
    text-align: center;
  }

  .AdminIndex .Employee .EmployeeHeader .EmployeeHeaderLeft .pageTitle,
  .AdminIndex .Customer .CustomerHeader .CustomerHeaderLeft .pageTitle,
  .AdminIndex .Location .LocationHeader .LocationHeaderLeft .pageTitle,
  .AdminIndex .adminNotification .ProfileHeader .ProfileHeaderLeft .pageTitle,
  .AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderLeft .pageTitle,
  .AdminIndex .AddCarPage .manageAddCarHeader .manageAddCarHeaderLeft .pageTitle,
  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderLeft .pageTitle,
  .AdminIndex .Profile .ProfileHeader .ProfileHeaderLeft .pageTitle,
  .AdminIndex .Promocode .Promocodeheader .PromocodeHeaderLeft .pageTitle,
  .AdminIndex .StaticPage .StaticPageHeader .StaticPageHeaderLeft .pageTitle,
  .AdminIndex .EmailTemplate .EmailTemplateHeader .EmailTemplateHeaderLeft .pageTitle,
  .AdminIndex .Setting .SettingHeader .pageTitle {
    font-size: 22px;
    /* line-height: 29px; */
    /* margin-bottom: 5px !important; */
  }

  .AdminIndex .Employee .EmployeeHeader .EmployeeHeaderLeft .pageCountNumber,
  .AdminIndex .Customer .CustomerHeader .CustomerHeaderLeft .pageCountNumber,
  .AdminIndex .Location .LocationHeader .LocationHeaderLeft .pageCountNumber,
  .AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderLeft .pageCountNumber,
  .AdminIndex .Promocode .Promocodeheader .PromocodeHeaderLeft .pageCountNumber {
    font-size: 14px;
    line-height: 18px;
    font-family: "ff_bd";
  }

  .AdminIndex .Employee .EmployeeHeader .EmployeeHeaderLeft .EmployeeBackLink,
  .AdminIndex .Customer .CustomerHeader .CustomerHeaderLeft .CustomerBackLink,
  .AdminIndex .Location .LocationHeader .LocationHeaderLeft .LocationBackLink,
  .AdminIndex .adminNotification .ProfileHeader .ProfileHeaderLeft .NotificationBackLink,
  .AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderLeft .ManageCarBackLink,
  .AdminIndex .AddCarPage .manageAddCarHeader .manageAddCarHeaderLeft .manageAddCarBackLink,
  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderLeft .manageCarDetailBackLink,
  .AdminIndex .Profile .ProfileHeader .ProfileHeaderLeft .ProfileBackLink,
  .AdminIndex .Promocode .Promocodeheader .PromocodeHeaderLeft .PromocodeBackLink,
  .AdminIndex .StaticPage .StaticPageHeader .StaticPageHeaderLeft .StaticPageBackLink,
  .AdminIndex .EmailTemplate .EmailTemplateHeader .EmailTemplateHeaderLeft .EmailTemplateBackLink {
    font-size: 11px;
    line-height: 15px;
  }

  .AdminIndex .Employee .EmployeeHeader .EmployeeHeaderRight,
  .AdminIndex .Customer .CustomerHeader .CustomerHeaderRight,
  .AdminIndex .Location .LocationHeader .LocationHeaderRight,
  .AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderRight,
  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderRight,
  .AdminIndex .StaticPage .StaticPageHeader .StaticPageHeaderRight,
  .AdminIndex .EmailTemplate .EmailTemplateHeader .EmailTemplateHeaderRight {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .AdminIndex .Employee .EmployeeHeader .EmployeeHeaderRight .minWidth150 {
    min-width: 125px;
  }

  /* Customer */
  .AdminIndex .Customer .CustomerHeader .CustomerHeaderRight > div {
    width: 100%;
  }

  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderRight {
    align-items: center;
  }

  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderRight {
    flex-direction: column;
  }

  /* Manage Car */
  .AdminIndex .ManageCar .ManageCarHeader .ManageCarHeaderLeft .CarCategoryList > div {
    overflow-x: scroll;
    gap: 0.5rem;
  }

  .AdminIndex .AddCarPage .AddCar .PageHeading,
  .AdminIndex .AddCar .car-description-textarea .CarInputBox .form-label {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .AdminIndex .AddCarPage .AddCar .CarInputBox .form-label {
    font-size: 12px;
    line-height: 16px;
  }

  .AdminIndex .AddCarPage .AddCar .CarInputBox input,
  .AdminIndex .AddCarPage .AddCar .CarInputBox select {
    font-size: 15px;
    line-height: 20px;
  }

  .AdminIndex .AddCarPage .AddCar .addFeature .CarCheckBox .form-check-label {
    font-size: 14px;
    line-height: 20px;
  }

  /* manage Views */
  .AdminIndex .manageCarDetail .ViewDetail .CarPhotosList::-webkit-scrollbar {
    display: none;
  }

  .AdminIndex .manageCarDetail .ViewDetail .CarPhotosList {
    overflow-y: scroll;
    flex-wrap: inherit;
  }

  .AdminIndex .Setting .radioWithLabelActive,
  .AdminIndex .StaticPage .radioWithLabelActive {
    overflow-x: scroll;
    display: flex;
  }

  .AdminIndex .Setting .radioWithLabelActive::-webkit-scrollbar,
  .AdminIndex .StaticPage .radioWithLabelActive::-webkit-scrollbar {
    display: none !important;
  }

  /* Promocode */
  .Promocode .Promocodeheader .createPromoCodeButton {
    margin: 0.5rem auto 0 auto;
  }

  .adminMobileIdentityDocument .row .adminVerifyDocument,
  .adminBookingCarPhotoListDesc .CarPhotosList {
    width: 100% !important;
  }

  .adminMobileIdentityDocument .row,
  .adminBookingCarPhotoListDesc {
    display: grid !important;
  }

  .BookingViewModal .adminBookingDetailHeader {
    height: 85px;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 9;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .requesttypeinmodal .TabContainer .TabChildren:last-child,
  .upcomingtypeinmodal .TabContainer .TabChildren:last-child {
    display: none;
  }

  .BookingViewModal .ant-modal-close-x {
    position: fixed;
  }

  .BookingViewModal .adminBookingDetailBody {
    margin-top: 85px;
    overflow-y: scroll;
  }

  .AdminIndex .Booking {
    padding-top: 80px !important;
  }

  .AdminIndex .bookingListingCard > div {
    max-height: 372px;
  }

  .BookingViewModal .adminBookingUploadDoc .drivingIdentification .row .col-12 {
    width: 100%;
  }

  .AdminIndex .Setting .GeneralItemsAdd .text-danger {
    bottom: -15px;
  }

  .BookingViewModal .PaymentAmount .selectButtonOfPay {
    display: none !important;
  }
}

@media screen and (max-width: 1124px) {
  .StaticPage .ant-table-container {
    /* overflow: scroll; */
    overflow: scroll;
  }

  .StaticPage .ant-table-container::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .adminMobileIdentityDocument .row,
  .adminBookingCarPhotoListDesc {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  /* Booking */
  .AdminIndex .bookingListingCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .AdminIndex .bookingListingCard > div {
    margin-bottom: 0 !important;
  }

  .adminMobileIdentityDocument .row,
  .adminBookingCarPhotoListDesc {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: 991px) and (max-width: 1399px) {
  .AdminIndex .Setting .radioWithLabelActive {
    overflow-x: scroll;
    display: flex;
  }

  .AdminIndex .Setting .radioWithLabelActive::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 998px) {
  .BookingViewModal .TabContainer:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    display: block;
    bottom: 0;
    background: #dadada;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  /* Booking */
  .selectedBookingCard .editCarPhotos button {
    min-width: 135px;
  }

  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderRight {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
  }

  .AdminIndex .manageCarDetail .manageCarDetailHeader .manageCarDetailHeaderLeft {
    text-align: center;
  }

  /* Employee */
}
