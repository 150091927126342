.AdminIndex .InputBox {
  width: 100%;
  min-height: 50px;
  margin-top: 20px;
}

.AdminIndex .InputBox label {
  font-family: "ff_rg";
  font-size: 13px;
  line-height: 17px;
  color: var(--light-md);
}

.AdminIndex .InputBox input {
  margin-top: 0;
  border: 1px solid var(--light-md);
  border-radius: 12px;
}

.ant-modal-body .InputBox textarea {
  min-height: 75px;
}

.ant-modal-body .InputBox .text-danger {
  padding-top: 10px;
  display: block;
}

.GeneralItemsAdd .user-select-none {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.ant-modal-body .InputBox textarea {
  background-color: var(--primary-opacity);
  /* border-color: transparent; */
}
/* Model Pop UP */
