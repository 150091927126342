/* --- Stat Card --- */
.StatCard .Icon {
  margin-top: 20px;
  margin-left: 20px;
  width: 50%;
  height: 50%;
}
.StatCard .Title {
  margin-top: 25px;
  margin-bottom: 0px;
  font-family: "ff_rg";
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
}

.StatCard .Description {
  font-family: "ff_md";
  font-weight: 500;
  font-size: 24px;
  line-height: normal;
}
/* --- --- */

/* --- Info Card --- */
.InfoCard {
  padding: 20px 10px;
  color: var(--dark);
  font-size: 14px;
}
.InfoCard .SmText,
.InfoCard .LgText {
  font-family: "ff_bd";
}
.InfoCard .MdText {
  font-family: "ff_md";
}

.InfoCard .avatarContainer {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}
.InfoCard .avatarContainer img {
  width: 100%;
  height: 100%;
}
.InfoCard .callContainer {
  width: 25px;
  height: 25px;
  margin-top: 2px;
}
.InfoCard .avatarContainer,
.InfoCard .callContainer {
  border-radius: 20px;
  background-color: var(--dark);
}
.InfoCard .nameContainer {
  margin-top: 5px;
  font-size: 16px;
}
.InfoCard .ant-timeline {
  height: min-content;
}
.InfoCard .ant-timeline-item-last {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.InfoCard .EarningIndicator {
  font-size: 15px;
}
/* --- --- */

/* --- Employee Card --- */
.AdminIndex .Employee .addEmployeeBtn,
.AdminIndex .Customer .addEmployeeBtn {
  height: 48px !important;
  font-size: 1rem;
}
.AdminIndex .EmployeeCard .ToggleButton,
.AdminIndex .CustomerCard .ToggleButton,
.AdminIndex .LocationCard .ToggleButton {
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  text-align: end;
  width: auto;
  padding: 0;
  margin: 0;
}
.AdminIndex .EmployeeCard .ToggleButton button,
.AdminIndex .CustomerCard .ToggleButton button,
.AdminIndex .LocationCard .ToggleButton button {
  background: transparent;
}
.AdminIndex .EmployeeCard .ToggleButton .ToggleBGlist,
.AdminIndex .CustomerCard .ToggleButton .ToggleBGlist,
.AdminIndex .LocationCard .ToggleButton .ToggleBGlist {
  background: var(--light);
  text-align: start;
  min-width: 150px;
  display: none;
  transition: all 0.25s ease;
}
.AdminIndex .EmployeeCard .ToggleButton .ToggleBGlist.is-active,
.AdminIndex .CustomerCard .ToggleButton .ToggleBGlist.is-active,
.AdminIndex .LocationCard .ToggleButton .ToggleBGlist.is-active {
  display: block;
}
.AdminIndex .EmployeeCard .ToggleButton .ToggleBGlist ul li,
.AdminIndex .CustomerCard .ToggleButton .ToggleBGlist ul li,
.AdminIndex .LocationCard .ToggleButton .ToggleBGlist ul li {
  font-family: "ff_md";
  font-size: 12px;
  line-height: 2rem;
  list-style: none;
  cursor: pointer;
}
.addEmployeeModal .ant-modal-body .text-center,
.carListUpdateLocation .ant-modal-body .text-center {
  font-size: 24px;
  line-height: 24px;
  font-family: "ff_bd";
}

.addPickupLocation .ant-modal-body .InputBox {
  height: 100%;
}

.addEmployeeModal .ant-modal-body .InputBox input,
.addPickupLocation .ant-modal-body .InputBox input,
.addPromoCode .ant-modal-body .InputBox input,
.addPromoCode .ant-modal-body .InputBox textarea {
  background: var(--primary-opacity);
  border-radius: 6px;
  height: 50px;
  border: 1px solid #ced4da;
}
.addEmployeeModal .ant-modal-body .InputBox input:focus,
.addPickupLocation .ant-modal-body .InputBox input:focus,
.addPromoCode .ant-modal-body .InputBox input:focus,
.ant-modal-body .InputBox textarea:focus {
  outline: 1px solid var(--primary);
  box-shadow: none;
}

.addPromoCode .form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: none;
}

.addEmployeeModal .ant-modal-body .InputBox #container,
.addPickupLocation .ant-modal-body .InputBox #container {
  height: 100%;
}
.antDButton .antDCancel,
.antDButton .antDAdd {
  height: 50px;
  border-radius: 12px;
  font-family: "ff_md";
}

.manageLocationModel .searchForaPlace {
  padding: 0;
}

.manageLocationModel .searchLocation label {
  margin-bottom: 0.4rem;
}
/* --- --- */

/* --- All Card Common --- */
.StatCard,
.InfoCard,
.EmployeeCard,
.CustomerCard {
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid var(--light);
  background-color: #ffffff;
}
/* --- --- */
/* Location Card */
.LocationCard {
  width: 400px;
  padding: 0.75rem;
}
/* Location Card End*/
/* Promo Code */
.addPromoCode textarea {
  resize: none;
}
/* Profile */
.adminProfileAvatar img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.adminProfileAvatarButton input {
  display: none;
}

.adminProfileAvatarButton button {
  background-color: var(--primary);
  border: 1px solid var(--primary-opacity);
  border-radius: 10px;
  height: 44px;
  padding: 0 1rem;
  font-family: "ff_md";
  font-size: 13px;
  color: var(--light);
}

/* Profile End*/
/* Notification */
.AdminIndex .adminNotification .adminNotificationBody .NotificationCard {
  border-bottom: 1px solid var(--light-md);
  border-top: 1px solid var(--light-md);
}

.AdminIndex .adminNotification .adminNotificationBody .NotificationCard:first-child {
  border-top: 1px solid transparent;
}

.AdminIndex .adminNotification .adminNotificationBody .NotificationCard:last-child {
  border-bottom: 1px solid var(--primary-opacity);
}

.AdminIndex .adminNotification .adminNotificationBody .NotificationCard:hover {
  border-color: transparent;
  background: var(--primary-opacity);
  border-radius: 6px;
}

.AdminIndex .adminNotification .adminNotificationBody .notificationTime {
  width: auto !important;
  right: 0;
  top: 10px;
}

@media screen and (max-width: 768px) {
  .AdminIndex .adminNotification .adminNotificationBody .notificationTime,
  .ViewNotificationTemplateModal .notificationTime {
    position: relative !important;
    top: 0;
    padding: 0 !important;
  }
}

/* Notification End*/

.AdminIndex .Dashboard .dashboardRecentCarBooking .adminTimelineStyle .ant-timeline-item-tail {
  height: calc(100% - 16px);
  inset-block-start: 13px;
}
