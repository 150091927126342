.AdminIndex .Header {
  border-bottom: 2px solid var(--light);
  margin-bottom: 10px;
}

.AdminIndex .Header .IconContainer {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--dark);
  margin: 10px 10px 30px 10px;
}

.AdminIndex .Header .headerLaunguage ul {
  position: absolute;
  left: 0;
  top: 45px;
  background: var(--light);
  /* box-shadow: 0px 6px 14px var(--primary); */
  border-radius: 12px;
  min-width: 150px;
  display: none;
  z-index: 99;
}

.AdminIndex .Header .headerLaunguage button {
  background: var(--light);
  border: 1px solid var(--light-md);
  border-radius: 10px;
  height: 36px;
}

.AdminIndex .Header .headerLaunguage.is-active ul {
  display: block;
}

.AdminIndex .Header .headerLaunguage ul li {
  line-height: 40px;
}

.AdminIndex .Header .headerLaunguage ul li input[type="radio"] {
  width: 14px;
  height: 14px;
  accent-color: var(--primary);
}

.adminNotificationSquare div {
  width: 15px;
  height: 15px;
  font-size: 9px;
  position: absolute;
  top: -2px;
  right: -4px;
  background-color: rgba(170, 60, 60, 1) !important;
}
