/* --- Navigation Bar --- */
.NavigationBar {
  position: fixed;
  top: 0px;
  left: 10px;
  width: 65px;
  background-color: var(--white);
  border-radius: 12px 12px 0 0;
  padding: 8px 0px 10px 0px;
  overflow: hidden;
  transition: all 0.25s ease;
  z-index: 10;
  height: 100%;
}
.NavigationBar:hover {
  width: 200px;
}
/* --- --- */

/* --- Navigation Link --- */
.NavigationBar .NavigationLink {
  width: 200px;
  height: 42px;
  text-decoration: none !important;
  color: inherit;
  align-items: center;
}

.NavigationBar .NavIconBG {
  height: 32px;
  width: 32px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.NavigationBar .NavigationLink .labelContainer {
  width: 125px;
  font-family: "ff_md";
  font-size: 10px;
  line-height: 13px;
  color: var(--light-md);
  margin-left: 10px;
  opacity: 0;
}
.NavigationBar:hover .NavigationLink .labelContainer {
  opacity: 1;
}
/* --- --- */
.NavigationBar .NavigationLink.active .NavIconBG {
  background: var(--primary);
}

.NavigationBar .NavigationLink.active .NavIconBG svg [fill="#0e344b"] {
  fill: var(--light);
}

.NavigationBar .NavigationLink.active .NavIconBG svg [stroke="#0e344b"] {
  stroke: var(--light);
}

.NavigationBar .NavigationLink.active .labelContainer {
  color: var(--primary);
}

.NavigationBar .NavigationLink.active .NavIconBG svg [fill="var(--primary)"],
.adminMenubar .NavigationLink.active .NavIconBG svg [fill="var(--primary)"] {
  fill: var(--light);
}

.NavigationBar .NavigationLink[data-image="All Customer"] {
  position: relative;
}

.NavigationBar .adminSidebarNumberSet {
  font-size: 7px;
  position: absolute;
  z-index: 9;
  color: var(--light);
  left: 36px;
  top: 16px;
}

.NavigationBar .NavigationLink[data-image="All Customer"].active .adminSidebarNumberSet {
  color: var(--dark);
}

.NavigationBar .NavigationLink[data-image="All Customer"].active svg [fill="var(--dark)"] {
  fill: var(--light);
}
