/* -- Static CSS-- */
hr {
  border-top: 1px solid var(--light-md) !important;
}

/* -- Font CSS-- */
.f40 {
  font-size: 40px;
  line-height: 48px;
}

.f38 {
  font-size: 38px;
  line-height: 3.093rem;
}

.f34 {
  font-size: 34px;
  line-height: 44px;
}

.f24 {
  font-size: 24px;
  line-height: 30px;
}

.f20 {
  font-size: 20px;
  line-height: 24px;
}

.f18 {
  font-size: 18px;
  line-height: 24px;
}

.f16 {
  font-size: 16px;
  line-height: 20px;
}

.f14 {
  font-size: 14px;
  line-height: 18px;
}

.f12 {
  font-size: 12px;
  line-height: 16px;
}

.f10 {
  font-size: 10px;
  line-height: 12.5px;
}

.f11 {
  font-size: 11px;
  line-height: normal;
}

.w-12 {
  width: 12px;
}

.tp-1 {
  top: -1px;
}
/* -- Font End-- */
/* backgroundColor */
.backGroundStyleOne {
  background-color: #343a3f;
}

.backGroundStyleThree {
  background-color: rgba(52, 58, 63, 0.7);
}

.backGroundStyleThreeOpacityOne {
  background-color: rgba(52, 58, 63, 0.13);
}

.backGroundStyleFour {
  background-color: rgba(233, 238, 241, 1);
}

.backGroundStyleFive {
  background-color: rgba(217, 217, 217, 1);
}

.backGroundStyleFiveTwo {
  background-color: rgba(217, 217, 217, 0.2);
}

.backGroundStyleSix {
  background-color: rgba(246, 246, 246, 1);
}

.backgroundOne {
  background: #f5f7f7;
}

.backgroundTwo {
  background: rgba(55, 55, 55, 1);
  color: #ffffff;
}

.backgroundThree {
  background: #f5f7f7;
}

.backgroundFour {
  background: #ededed;
}

/* backgroundColor End*/
/* -- Text CSS-- */
.textPrimary,
.textPrimary:hover {
  color: #343a3f;
}

.textPrimaryOpacity {
  color: rgba(52, 58, 63, 0.5);
}

.textOrange {
  color: rgba(225, 108, 0, 1);
}
.textPrimaryFive {
  color: rgba(39, 39, 39, 0.5);
}
/* -- Text CSS-- */
/* Height */
.h54 {
  height: 54px;
}

.h48 {
  height: 48px;
}

.h40 {
  height: 40px;
}

.h36 {
  height: 36px;
}

.h32 {
  height: 32px;
}

.unit70vh {
  height: 70vh;
}

.unit80vh {
  height: 80vh;
}

.h50 {
  height: 50px;
}

.minHeight56 {
  min-height: 56px;
}
/* Height End*/
/* Width */
.minWidth90 {
  min-width: 90px;
}

.minWidth100 {
  min-width: 100px;
}

.minWidth125 {
  min-width: 125px;
}

.minWidth150 {
  min-width: 150px;
}

.minWidth175 {
  min-width: 175px;
}

.minWidth200 {
  min-width: 200px;
}

.maxWidth125 {
  max-width: 125px;
}

.min-fit-content {
  min-width: fit-content;
}
/* Border */
.borderStyleOne {
  border: 1px solid #d4dee6;
}

.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.borderStyleThree {
  border: 1.5px solid rgba(0, 0, 0, 1);
}
/* Gap */
.GapOne {
  column-gap: 1rem;
}

.GapOneHalf {
  column-gap: 0.5rem;
}

/* -- Square Button -- */
.SquareButton {
  width: 36px;
  height: 36px;
  border: 1px solid var(--primary);
  border-radius: 6px;
  background-color: #fff;
}

/* -- Custom Button -- */
.CustomerButton {
  background: #343a3f;
  border-radius: 12px;
  height: 46px;
  min-width: 100px;
  color: #fff;
}

.CustomerIndex .css-dev-only-do-not-override-1me4733 .ant-modal-content {
  height: 27.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.CustomerIndex .modelpopstyle h4 {
  font-family: "ff_bd";
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  color: #171717;
}

.CustomerIndex .modelpopstyle p {
  font-family: "ff_rg";
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #000000;
  max-width: 355px;
  margin: 0 auto;
}

.CustomerIndex .ant-modal-footer {
  text-align: center;
}

.CustomerIndex .ant-modal-footer .CarrerPopClose {
  background: rgb(214, 216, 217);
  box-shadow: 0px 1rem 1.25rem rgba(38, 153, 251, 0.06);
  border-radius: 0.75rem;
  width: 9.375rem;
  height: 3.125rem;
  font-family: "ff_md";
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #343a3f;
}

.CustomerIndex :where(.css-dev-only-do-not-override-1me4733).ant-btn-default:not(:disabled):hover {
  color: inherit;
  border-color: transparent;
}

.DatepickerRange .location .ant-picker-range.ant-picker-focused .ant-picker-suffix {
  opacity: 0;
}

.DatepickerRange .location :where(.NewDatePicker).ant-picker-range:hover .ant-picker-clear {
  background: transparent;
  right: 0.4rem;
}
.smallSquareBtn {
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.customerCarReview .ant-modal-content .maxReview {
  max-height: 500px;
  overflow-y: scroll;
}

.ant-picker-dropdown {
  font-family: "ff_rg";
}
@media screen and (max-width: 767.98px) {
  .SearchCarDetails .goBackIcon,
  .BookingEdit .goBackIcon,
  .yourBookingViewDetials .goBackIcon,
  .HowItWorks .goBackIcon,
  .customerHelp .goBackIcon,
  .RentalInformation .goBackIcon,
  .LostAndFound .goBackIcon,
  .customerNotification .goBackIcon,
  .adminSelectedBooking .goBackIcon,
  .Employee .goBackIcon,
  .Customer .goBackIcon,
  .ManageCar .goBackIcon,
  .AddCarPage .goBackIcon,
  .ViewDetails .goBackIcon,
  .Promocode .goBackIcon,
  .Setting .goBackIcon,
  .pt-70 .goBackIcon {
    width: 10px;
    position: relative;
    top: -1px;
  }
}

p + p {
  margin-top: 0;
}

.BlogPhotos .ant-upload-list-picture-card:has(.ant-upload-list-item-container) .ant-upload-select {
  display: none;
}

.BlogPhotos .ant-upload-list-item-container,
.BlogPhotos .ant-upload-select {
  width: 100% !important;
}

.NotificationTemplate .ant-table-thead tr th:last-child {
  width: 116px;
}

.adminHelpCategory .BlogPhotos .ant-upload-select {
  margin-bottom: 0 !important;
}

/* Date Picker */
.date-time-range-picker-modal .time-picker button[value="18:15"],
.date-time-range-picker-modal .time-picker button[value="18:30"],
.date-time-range-picker-modal .time-picker button[value="18:45"] {
  display: none !important;
}

@media screen and (max-width: 420px) {
  .date-time-range-picker-modal .time-picker .ant-btn {
    min-width: 60px !important;
    max-width: 60px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}
