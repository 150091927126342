/* --- Button --- */
.AdminIndex .Button {
  width: 150px;
  height: 40px;
  background: var(--dark);
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: "ff_md";
  font-size: 14px;
  line-height: 18px;
}
/* --- --- */

/* --- Header Button --- */
.AdminIndex .HeaderButton {
  height: 40px;
  background: var(--dark);
  color: var(--light);
  border-radius: 8px;
  margin: 0px 10px;
  padding: 0px 40px;
  font-family: "ff_md";
  font-size: 14px;
  line-height: 18px;
}
.AdminIndex .HeaderButton span {
  margin-left: 10px;
  vertical-align: text-bottom;
}
/* --- --- */
