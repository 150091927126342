/* Feature List */
/* .mileage-red p,
.listOfCarStyle.premiumCar .mileage-red p {
  color: var(--error) !important;
}

.mileage-red [fill="var(--primary)"],
.listOfCarStyle.premiumCar .mileage-red [fill="var(--primary)"] {
  fill: var(--error) !important;
}

.mileage-red [fill="black"],
.listOfCarStyle.premiumCar .mileage-red [fill="black"] {
  fill: var(--error) !important;
}

.mileage-red,
.listOfCarStyle.premiumCar .mileage-red {
  border-color: var(--error) !important;
} */

.homePage .banner_section .banner-select-option .discount-message {
  border-radius: 0 0 0.25rem 0.25rem;
  color: var(--secondary);
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
