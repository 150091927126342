.AdminIndex .searchBar {
  display: inline-flex;
  background-color: var(--white);
  border: 1px solid var(--primary-opacity);
  border-radius: 6px;
  height: 40px;
}

.AdminIndex .searchBar .searchInput {
  background-color: var(--white);
  padding-left: 10px;
  height: 100%;
  width: 100%;
  font-size: 13px;
  line-height: 17px;
  color: var(--primary);
  padding-right: 30px;
}

.AdminIndex .searchBar .searchInput::placeholder {
  color: var(--primary);
}

.AdminIndex .searchBar .searchIcon {
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translate(-25%, -50%);
  right: 0;
}

.AdminIndex .searchBar .searchIcon * {
  stroke: var(--primary);
}

.AdminIndex .searchBar .crossIcon svg {
  position: absolute;
  top: 50%;
  transform: translate(-25%, -50%) scale(0.8);
  right: 0;
  cursor: pointer;
}

.settingLoader {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
