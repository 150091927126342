/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px var(--light);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 6px;
}

/* DashBoard */
.dashBoardDatePicker {
  right: 0rem;
}
.AdminIndex::before {
  content: "";
  background-color: var(--light) !important;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: -9;
}

/* Dashboard End */
.AdminIndex .Views .PageSection {
  margin: 20px 0px;
  color: var(--dark);
}
.AdminIndex .Views .PageTitle {
  font-family: "ff_bd";
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 0px;
}
.AdminIndex .Views .PageSubTitle {
  font-family: "ff_rg";
  font-size: 14px;
  line-height: 18px;
}
.AdminIndex .Views .PageSectionContainer {
  margin-top: 10px;
}
/* --- Heading Like Manage Car --- */
.GotoBack,
.GotoBack:hover {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--dark);
}
/* --- Heading Like Manage Car --- */
.AdminIndex .PageHeading {
  font-family: ff_bd;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: var(--primary);
  margin-bottom: 0;
}

.newHeadingSpace .PageHeading {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
/* <h4 className="PageHeading"></h4> */
/* --- CarInputBox Styling --- */
.AdminIndex .CarInputBox input,
.AdminIndex .CarInputBox select,
.AdminIndex .CarInputBox .carExteriorColor,
.AdminIndex .CarInputBox .customDropdown,
.carListUpdateLocation .CarInputBox .carExteriorColor {
  background-color: var(--primary-opacity);
  border: 1px solid #ced4da;
  border-radius: 0.75rem;
  height: 3.375rem;
  font-family: "ff_rg";
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: var(--dark);
  padding-left: 1rem;
}

.AdminIndex .CarInputBox .form-label,
.AdminIndex .ViewDetails .CarSpecification .CarSpecList h4 {
  font-family: "ff_rg";
  font-size: 0.813rem;
  line-height: 1.063rem;
  color: var(--primary);
}

.AdminIndex .CarInputBox input:focus {
  box-shadow: none;
  border: 1px solid var(--dark);
}

.AdminIndex .CarInputBox .notes {
  font-family: "ff_rg";
  font-size: 0.625rem;
  line-height: 0.813rem;
  color: var(--dark);
}

.AdminIndex .RadioBoxDefalut .radioBoxLabel {
  font-family: "ff_rg";
  margin-left: 0.4rem;
  font-size: 0.813rem;
  line-height: 1.063rem;
}

.AdminIndex .RadioBoxDefalut .RadioBox input {
  height: 1rem;
  width: 1rem;
  accent-color: var(--primary);
}

.AdminIndex .FormSubmitButton {
  width: 130px;
}

/* Color Select */
.AdminIndex .CarInputBox .carExteriorColorData,
.AdminIndex .CarInputBox .customDropdownData,
.AdminIndex .CarInputBox .carLocaionList,
.carListUpdateLocation .CarInputBox .carLocaionList {
  width: calc(100% - 1.5rem);
  display: none;
  z-index: 1;
}

.AdminIndex .CarInputBox .carExteriorColorData.is-active,
.AdminIndex .CarInputBox .customDropdownData.is-active,
.AdminIndex .CarInputBox .carLocaionList.is-active,
.carListUpdateLocation .CarInputBox .carLocaionList.is-active {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

.AdminIndex .CarInputBox .carExteriorColorData .carExteriorColorLabel input,
.AdminIndex .CarInputBox .customDropdownData input,
.AdminIndex .CarInputBox .carLocaionList input,
.carListUpdateLocation .CarInputBox .carLocaionList input {
  width: 0;
  height: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
}

.AdminIndex .CarInputBox .carExteriorColorData .carExteriorColorHexa,
.AdminIndex .CarInputBox .carExteriorColor .carExteriorColorSetHexa {
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
}

/* .AdminIndex .CarInputBox .carExteriorColorData.is-active::-webkit-scrollbar {
  border-radius: 0px 6px 6px 0px !important;
  background-color: #fff !important;
} */

/* Checkbox */

.AdminIndex .CarCheckBox .form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

/* --- CarInputBox Styling --- */
/* --- DefalutParagraphStyle --- */
.ParagraphStyle {
  font-family: "ff_md";
  font-size: 1rem;
  line-height: 1.313rem;
  color: var(--dark);
}
/* --- Booking Page --- */
.AdminIndex .bookingListingCard > div {
  border: 1.5px solid transparent;
  border-color: transparent;
  transition: ease 0.5s;
}

.AdminIndex .bookingListingCard > div.isActive {
  border-color: var(--primary);
  border-width: 2px;
}

.AdminIndex .bookingListingCard::-webkit-scrollbar {
  width: 6px;
}

.AdminIndex .bookingListingCard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
}

.AdminIndex .bookingListingCard::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

.AdminIndex .radioWithLabelActive input,
.carEditPhotosModel .radioWithLabelActive input {
  width: 0;
  height: 0;
  opacity: 0;
  min-width: unset;
  min-height: unset;
}

.AdminIndex .radioWithLabelActive label:has(input:checked),
.carEditPhotosModel .radioWithLabelActive label:has(input:checked) {
  background: var(--primary) !important;
  color: var(--light);
}

/* Car Edit Photos */
.AdminIndex .CarEditPhotos .CarPhotosList {
  position: relative;
}

.AdminIndex .CarEditPhotos .EditUploadPhotos {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.bookingCompletedRideFilter .ant-picker-input,
.bookingCompletedRideFilter .ant-picker-range {
  display: flex;
  flex-direction: row-reverse;
}

.bookingCompletedRideFilter .ant-picker-input span.ant-picker-suffix,
.bookingCompletedRideFilter .ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 5px;
}

.bookingCompletedRideFilter .ant-picker-input input {
  margin-left: 10px !important;
  font-family: "ff_md";
}

/* Booking Completed -  */
.selectedCalender .bookingDatePicker {
  width: 150px;
}

.AdminIndex .Booking .selectedBookingCardImage img,
.mobileUpcoming .selectedBookingCardImage img {
  width: 100%;
  height: 360px;
  object-fit: contain;
}

.AdminIndex .Booking .selectedBookingCardImage img,
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-default {
  cursor: default !important;
}

/* General Setting Add */
.GeneralItemsAdd .addItemsList img {
  width: 20px;
  background-color: var(--primary-opacity);
  border-radius: 6px;
}

.GeneralItemsAdd .addItemsList .remove-img-btn {
  right: 0.4rem;
  top: 0.4rem;
  transform: scale(0.8);
  background-color: transparent;
}

.GeneralItemsAdd .form-control {
  box-shadow: none;
}

.GeneralItemsAdd .form-control:focus {
  border-color: var(--dark);
}

/* Promocode */
.ant-modal-body .promoCodeDescription #container {
  height: 100%;
}

/* Adjustment Rendtal in birth Date */
.birthDateField input {
  font-family: "ff_md";
}
@media screen and (max-width: 767.98px) {
  .Promocode .Promocodeheader,
  .Setting .SettingHeader {
    flex-direction: column;
    /* padding: 2rem 0; */
  }

  .Promocode .Promocodeheader .promocodeHeading,
  .Setting .SettingHeader {
    margin-bottom: 0.2rem !important;
    font-size: 22px;
    line-height: 29px;
  }

  .Promocode .Promocodeheader .promocodebacktodashboard,
  .Setting .Settingbacktodashboard {
    font-size: 11px;
    line-height: 15px;
  }

  .PromoCodeCard .f12 {
    font-size: 10px;
    line-height: 13px;
  }

  .PromoCodeCard .f18 {
    font-size: 14px;
    line-height: 18px;
  }

  .PromoCodeCard .f14 {
    font-size: 12px;
    line-height: 16px;
  }

  .PromoCodeCard button {
    height: 36px;
  }

  .PromoCodeCard .DateIcon {
    width: 16px;
  }
}

.AdminIndex .carlistandsearch .CarInputBox .carExteriorColorData.is-active {
  width: calc(100% - 0.75rem);
}

.bookingDatePicker.is-active .ant-picker-suffix,
.birthDateField.is-active .ant-picker-suffix {
  opacity: 0;
}

.bookingDatePicker.is-active .ant-picker-clear,
.birthDateField.is-active .ant-picker-clear {
  opacity: 1;
}

.cursor-grab {
  cursor: grab;
}

.AdminIndex .Booking .bookingAdminAvatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
