p {
  margin-bottom: 0;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1 !important;
}

/* -- Custom CheckBox -- */
.CustomerIndex .CustomCheckBox .form-check-input {
  border-color: var(--primary) !important;
}

.CustomerIndex .CustomCheckBox .form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.CustomerIndex .CustomCheckBox .form-check-input[type="checkbox"] {
  border-radius: 50%;
  font-size: 22px;
  margin-top: 0;
}

.CustomerIndex .CustomCheckBox .form-check-input:focus {
  box-shadow: none;
  border-color: var(--dark);
}

/* -- Indivisual And Business Radiobutton -- */
.setTypeRadio {
  display: flex;
  column-gap: 1rem;
}

.setTypeRadio .RadioBox {
  border: 1px solid var(--dark);
  background: transparent;
  min-height: 36px;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  border: 1px solid rgba(29, 31, 62, 0.15);
  border-radius: 12px;
}

.setTypeRadio .RadioBox:has(input:checked) {
  background: #d9d9d9;
}

.setTypeRadio .RadioBox input[type="radio"] {
  accent-color: var(--light);
  margin-right: 0.4rem;
}

/* -- Customer Input -- */
.CustomerInput label,
.CustomerInput p {
  font-family: "ff_rg";
  font-size: 0.813rem;
  line-height: 1rem;
  color: var(--dark);
}

.CustomerInput .form-control,
.birthDateField .form-input-type {
  background-color: var(--primary-opacity) !important;
  border-radius: var(--border-radius-small);
  height: 3.375rem;
  font-family: "ff_md";
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: var(--dark);
  padding-left: 1rem;
}

.CustomerInput input:focus {
  box-shadow: none;
  border: 1px solid var(--primary);
}

.CustomerInput textarea {
  min-height: 100px;
  resize: none;
}

.CustomerInput textarea.form-control:focus {
  box-shadow: none;
  border-color: rgb(23 23 23 / 100%);
}

/* PickUpLocation Components  */
.PickUpLocation .location {
  min-height: 56px;
  cursor: pointer;
}

.PickUpLocation .locationData {
  position: absolute;
  width: 100%;
  box-shadow: 0px 6px 14px rgba(52, 58, 63, 0.08);
  display: none;
  z-index: 9;
}

.PickUpLocation .locationData.is-active {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
  padding-bottom: 0 !important;
}

.PickUpLocation .locationData .form-check-input {
  visibility: hidden;
}

.PickUpLocation .locationData label:last-child {
  margin-bottom: 0.5rem !important;
}

.customerAntModal .PickUpLocation .locationData.is-active,
.ant-modal .locationData.is-active,
.ant-modal .selectCountryList.is-active,
.ant-modal .HelpArticleModelData.is-active {
  max-height: 150px;
  overflow-y: scroll;
}

.ant-modal .selectCountryList.is-active .countryLabel:last-child {
  margin-bottom: 0;
}

/* Progress Bar */
.Progressbar .processbar .ant-steps-item-wait .ant-steps-item-icon {
  width: 30px;
  height: 30px;
  background: url("../../Assets/Common/stepIncomplete.svg") no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  top: -10px;
  left: -10px;
}

.Progressbar .processbar .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0) !important;
}

.Progressbar .processbar .ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon,
.Progressbar .processbar .ant-steps-item-finish .ant-steps-item-icon {
  width: 30px;
  height: 30px;
  background: url("../../Assets/Common/stepComplete.svg") no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  top: -10px;
  left: -9px;
}

.Progressbar .processbar .ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon .ant-steps-icon-dot,
.Progressbar .processbar .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0) !important;
  border-color: transparent !important;
}

/* .Progressbar .processbar .ant-steps-item-tail::after {
  background: rgba(223, 227, 230, 1) !important;
} */

.Progressbar .processbar .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: rgba(52, 58, 63, 1) !important;
}

.Progressbar .processbar .ant-steps-item-description {
  font-family: ff_rg;
  font-size: 14px;
  line-height: 18px;
  display: block;
}

.Progressbar .processbar .ant-steps-item-finish .ant-steps-item-description {
  color: rgba(52, 58, 63, 1) !important;
}

/*  Slider */
.slick-prev:before,
.slick-next:before {
  content: "";
  display: none;
}

/* Radio Button With Label */
.CustomerIndex .radioWithLabelActive input {
  width: 0;
  height: 0;
  opacity: 0;
  min-width: unset;
  min-height: unset;
}

.CustomerIndex .radioWithLabelActive label:has(input:checked) {
  background: var(--primary) !important;
  color: #fff;
}

/* Pickup And Drop */

/* Car List */
.yourBookingRideList .PickUpPickDrop .ant-steps-item-content {
  position: relative;
  top: 10px;
}

.yourBookingRideList .PickUpPickDrop .ant-steps-item-title {
  font-family: ff_md;
  font-size: 0.75rem;
  line-height: inherit !important;
  color: var(--dark) !important;
  display: block;
  word-wrap: break-word;
}

.yourBookingRideList .PickUpPickDrop .ant-steps-item-description {
  font-family: ff_rg;
  font-size: 0.813rem;
  line-height: 1.063rem;
  color: var(--light-md) !important;
}

.yourBookingRideList .PickUpPickDrop .ant-steps-item-icon {
  border: 1.5px solid #343a3f !important;
  width: 15px !important;
  height: 15px !important;
  background: transparent;
  padding: 7.5px !important;
  position: relative;
  left: -3.75px !important;
}

.yourBookingRideList .PickUpPickDrop .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #343a3f !important;
  margin-top: 4px;
}

/* Custom Pop Up */
.custompopup {
  display: none;
  position: absolute;
  width: 100%;
  border: 1px solid rgba(52, 58, 63, 0.3);
  box-shadow: 0px 6px 14px rgb(52 58 63 / 8%);
  z-index: 9;
}

.custompopup.is-active {
  display: block;
}

.custompopup .form-check-input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.adminAntModal .custompopup.is-active {
  max-height: 150px;
  overflow-y: scroll;
}

/* --- Procode Details --- */
.yourBookingPriceDetails .form-input-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.yourBookingPriceDetails .form-input-type button {
  height: 3.375rem;
}

.yourBookingPriceDetails .PromoCodeApplyed {
  height: 56px;
}

.SearchPriceDetails .totalPay {
  height: 54px;
}

/* pagination */
.showCarListPagination {
  column-gap: 1rem;
}

.showCarListPagination button,
.showCarListPagination .CurrentPage {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: normal;
}

.showCarListPagination button {
  background-color: var(--primary);
  color: var(--light);
  min-width: 80px;
}

.showCarListPagination .CurrentPage {
  border: 1px solid var(--light-md);
  border-radius: 50%;
  background-color: var(--light-md);
}
/** Upload Image */
.carImageUpload input {
  display: none;
}

.carImageUpload .upload-image-text {
  font-family: "ff_rg";
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  color: rgba(55, 55, 55, 0.5);
}
/** Upload Image */

/* Ant Rate */
.ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 4px;
}

.ant-rate-disabled.ant-rate .ant-rate-star {
  cursor: pointer;
}

.ant-rate *,
.ant-rate,
.antRateText {
  pointer-events: none;
  opacity: 1 !important;
  font-size: 12px;
}

.ant-rate-star.ant-rate-star-full * {
  fill: var(--primary);
}

.ant-rate-star.ant-rate-star-zero *,
.ant-rate-star.ant-rate-star-half * {
  fill: rgba(14, 52, 75, 0.5);
}

.ant-rate-star-first {
  display: none;
}

.antRateText {
  vertical-align: bottom;
}

.WriteAReview .ant-rate *,
.WriteAReview .ant-rate {
  pointer-events: unset !important;
}
/* Switch */
.ant-switch-checked {
  background: var(--secondary) !important;
}

/* Image Preview Overlay */
.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

/* Close - Model Pop Up */
.ant-modal .ant-modal-close {
  right: 0.75rem;
  top: 0.75rem;
  width: 30px;
  height: 30px;
}

/* DateRangePicker */
.date-time-range-picker-modal .ant-btn-Cancel,
.date-time-range-picker-modal .ant-modal-footer .ant-btn {
  height: 36px;
  min-width: 75px;
  background-color: var(--primary-opacity);
  border-radius: 6px;
}

.customerCarReview.customerAntModal .ant-modal-content {
  border-radius: var(--border-radius-large);
}

.customerCarReview.customerAntModal .ant-modal-content::-webkit-scrollbar {
  border-radius: 0px 0px 0px 0px !important;
  background-color: transparent !important;
}

/* Antd Modal */
.ant-picker .ant-picker-clear {
  background-color: transparent;
}

.customerAntModal .ant-modal-content {
  padding: 20px 15px !important;
  box-sizing: border-box;
}

.customerAntModal .ant-modal-footer,
.adminAntModal .ant-modal-footer,
.footerNone .ant-modal-footer {
  display: none;
}

.ant-image-preview-operations-wrapper .ant-image-preview-operations {
  background: var(--secondary);
}

.customerCarReview {
  width: calc(100% - 15%) !important;
  max-width: 1360px;
}

.line-height-normal {
  line-height: normal;
}

.customerAntModal .cancelSuperHandler,
.customerAntModal .addSuperHandler,
.adminAntModal .cancelSuperHandler,
.adminAntModal .addSuperHandler {
  height: 54px;
  border-radius: var(--border-radius-small);
  font-family: "ff_bd", Arial, Helvetica, sans-serif;
  min-width: 90px;
  display: inline-block;
}

.modelMaxHeight .ant-modal-content {
  max-height: min(80vh, 700px);
  /* max-height: 500px; */
  overflow-y: scroll;
}

.modelMaxHeight .ant-modal-content::-webkit-scrollbar {
  border-radius: 0px 6px 6px 0px !important;
  background-color: #fff !important;
}

.ant-modal-content .form-check-input.wh22 {
  /* min-width: 14px;
  min-height: 14px; */
  flex: 0 0 14px;
}

.ant-tooltip .ant-tooltip-inner {
  min-width: fit-content;
}

.ant-tooltip.overlayClassName .ant-tooltip-inner {
  min-width: unset !important;
}

/* ToastContainer */
.carRental-ToastContainer {
  min-width: fit-content;
}

.carRental-ToastContainer .Toastify__close-button--light {
  background-color: transparent;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  opacity: 1;
  border: 1px solid var(--error);
  display: grid;
  place-items: center;
  transform: scale(0.7);
  right: -5px;
  top: -5px;
  position: relative;
}

.carRental-ToastContainer .Toastify__close-button--light svg {
  position: relative;
  left: 1px;
}

.carRental-ToastContainer .Toastify__close-button--light svg * {
  fill: var(--error);
}

@media screen and (max-width: 576px) {
  /* Antd All Modal CSS */
  .customerAntModal .modelSubmitHandlerButton .h54,
  .customerAntModal .modelSubmitHandlerButton button {
    height: 44px;
  }

  .customerAntModal .responsiveModalLabel,
  .adminAntModal .responsiveModalLabel,
  .customerAntModal .ant-modal-body .customerModalTitle {
    font-size: 22px;
    line-height: normal;
    max-width: 85%;
    margin: 0 auto;
  }

  .customerAntModal .cancelSuperHandler {
    height: 44px;
  }

  .PendingPaymentModal .modelSubmitHandlerButton button {
    width: 100%;
  }
}

@media screen and (max-width: 767.98px) {
  .customerCarReview .reviewCount {
    order: -1;
    text-align: center;
  }

  .customerCarReview .reviewCount {
    padding-top: 1rem;
  }

  .customerCarReview .reviewCarDescription,
  .customerCarReview .reviewCount,
  .customerCarReview .reviewStar {
    margin-bottom: 0.75rem;
  }

  .customerCarReview .reviewCarDescription div:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .customerCarReview .ReviewAvatar img {
    width: 70px;
    height: 70px;
  }

  .CustomerInput label,
  .CustomerInput p {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 991px) {
  .ant-image-preview-operations-progress {
    position: fixed;
    bottom: 40px;
    font-size: 20px;
    font-family: "ff_md";
  }

  .ant-image-preview-operations-wrapper .ant-image-preview-operations {
    justify-content: center;
  }
}

.ant-picker .ant-picker-input > input:hover {
  border: none;
}

.ant-picker .ant-picker-input > input:focus {
  border-color: var(--dark);
  border-inline-end-width: 1px;
}

/* .secondary svg [fill="#AA3C3C"] {
  fill: transparent;
  stroke: var(--primary);
  -webkit-text-stroke: medium;
}

.secondary svg [fill="white"] {
  fill: var(--primary);
} */

.searchForaPlace {
  border-radius: var(--border-radius-small);
  border: 1px solid #ced4da;
}

.mySwiper.item-center .swiper-wrapper {
  justify-content: center;
}
.mySwiper.item-center .swiper-wrapper .swiper-slide:last-child {
  margin-right: 0 !important;
}

.ant-table-wrapper .ant-table {
  font-family: "ff_rg";
}

.ant-table-wrapper .ant-table .ant-table-thead {
  font-family: "ff_bd";
}

.ant-table-wrapper .ant-table .ant-table-thead > tr > th {
  color: var(--primary);
}

.ant-image-mask-info {
  font-size: 0;
}

.ant-image-mask-info:after,
.anticon.anticon-eye,
.anticon-eye {
  font-size: 16px;
}

.english .ant-image-mask-info:after {
  content: "Preview";
}

.france .ant-image-mask-info:after {
  content: "Aperçu";
}

.dutch .ant-image-mask-info:after {
  content: "Voorbeeld";
}

.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.cursor-disable {
  cursor: not-allowed;
}
