/* Error Message */
.CustomerIndex .error-message {
  font-size: 0.75rem;
  line-height: 0.813rem;
  font-family: "ff_rg";
}

/* --- homePage --- */
.lightweightborder {
  box-shadow: rgb(177 189 199 / 8%) 0px 0.25rem 1.125rem;
}

.smallButton {
  min-width: 70px;
  height: 32px;
}

/* --- Ant Design Model --- */
.NewModelPopUp .antDButton button {
  color: rgb(52, 58, 63);
  border-radius: 0.375rem;
  min-width: 6.25rem;
}

.NewModelPopUp .antDButton .antDAdd {
  background-color: var(--primary);
  color: #fff;
  margin-left: 1rem;
}

.NewModelPopUp .antDButton .antDCancel {
  background-color: var(--primary-opacity);
}
/* --- Ant Design Model End --- */
/* --- Search Result Page --- */
/* --- Search Result Page - Search Filter --- */
.ant-picker .ant-picker-input > input:focus,
:where(.css-dev-only-do-not-override-1me4733).ant-picker .ant-picker-input > input-focused {
  border-color: transparent;
}

.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: var(--secondary);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--secondary);
}

.ant-picker-dropdown .ant-picker-cell::before {
  background: transparent;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner:hover,
.ant-picker-range .ant-picker-active-bar {
  background: rgba(201, 157, 76, 0.4);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
:where(.css-dev-only-do-not-override-1me4733).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: var(--secondary);
}

.ant-picker-footer .ant-btn-primary:not(:disabled):active {
  background-color: var(--secondary);
}

.ant-picker-dropdown .ant-picker-year-panel .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 8px !important;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 50% !important;
}

.ant-picker-footer .ant-picker-ok button,
.ant-picker-footer .ant-picker-ok button:hover {
  background-color: var(--primary);
  min-height: 36px;
  min-width: 90px;
  margin: 0.5rem 0;
  border-radius: 12px;
  color: var(--light);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today.ant-picker-cell-selected .ant-picker-cell-inner::before {
  border-color: transparent !important;
}

.ButtonList {
  column-gap: 1rem;
}

.SearchResult .MunichAirport {
  row-gap: 2rem;
}

.payTotalAmount img {
  width: 20px;
}

.freeCancellation img {
  width: 20px;
}

.CustomerIndex .searchPassengers input,
.CustomerIndex .searchBags input {
  width: 0;
  height: 0;
  opacity: 0;
  min-width: unset;
  min-height: unset;
}

.CustomerIndex .searchPassengers label:has(input:checked),
.CustomerIndex .searchBags label:has(input:checked) {
  background: var(--primary) !important;
  color: #fff;
}

.SearchCarDetails .carColor button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.SearchCarDetails .carColor button:before {
  content: "";
  width: 34px;
  height: 34px;
  position: absolute;
  border: 2px solid transparent;
  border-radius: 50%;
}

.SearchCarDetails .carColor button.is-active:before {
  border-color: var(--primary);
}

.selectColorListOfCar .SelectedColorCarCard .isSelectedIcon {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  z-index: 1;
}
.selectColorListOfCar .SelectedColorCarCard > div {
  border: 2px solid transparent;
}

.selectColorListOfCar .SelectedColorCarCard.active > div {
  border-color: #0e344b;
}

.selectColorListOfCar .SelectedColorCarCard > div .border-1 {
  border-color: #e5e5e5;
}

.SelectedColorCarCard * {
  pointer-events: none;
}

/* --- Search Result Page --- */

/* --- Sort Price --- */
.sideBarRightFilter {
  position: relative;
}

.PriceFilter {
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 1;
  box-shadow: 0px 6px 14px 0px rgba(52, 58, 63, 0.08);
  display: none;
}

.PriceFilter.is-active {
  display: block;
}

.PriceFilter ul li {
  height: 40px;
}

.PriceFilter ul li input[type="radio"] {
  width: 14px;
  height: 14px;
  accent-color: rgba(39, 39, 39, 1);
}

/* NewModelPopUp */
.NewModelPopUp li input[type="radio"] {
  width: 14px;
  height: 14px;
  accent-color: rgba(39, 39, 39, 1);
}

/* --- Driver Details --- */
.DriverDetails .CustomCheckBox .form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

/* --- Booking Details --- */
.searchBookingDetails img {
  width: 18px;
  height: 25px;
}

/* --- Procode Details --- */
.PromoCode .promoCodeInput .form-input-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.PromoCode .promoCodeInput .form-input-type button {
  height: 3.375rem;
  transition: all 0.25s ease;
}

.PromoCodeApplyed {
  height: 56px;
}

.SearchPriceDetails .totalPay {
  height: 54px;
}

.BookingPriceCost {
  min-width: 110px;
  text-align: end;
}

/* Common CSS */
.PageHeading {
  font-family: "ff_bd";
  font-size: 34px;
  line-height: 44px;
  color: var(--primary);
}

.LargeButton {
  background: #343a3f;
  border-radius: 12px;
  font-family: "ff_bd";
  color: #ffffff;
  max-width: 300px;
  width: 100%;
  height: 54px;
  min-width: max-content;
}

.MediumButton,
.MediumButton:hover {
  background: #343a3f;
  border-radius: 12px;
  font-family: "ff_bd";
  color: #ffffff;
  max-width: 200px;
  width: 100%;
  height: 54px;
}

/* Search Booking By ID  home/booking/:id*/
.BookingIndex .bookingDividePickupDrop {
  margin-top: 3rem;
}

/* Adjust Rental Filled */
.AdjustRental .BookingSelectDay.selected > div,
.AdjustRental .ChildSeat.selected > div,
.AdjustRental .SearchYesNo.selected > div {
  background: #d9d9d9;
}

/* TimeOut */
.bookingTimeOut {
  background: #343a3f;
  border-radius: 12px;
  height: 54px;
  color: rgba(255, 255, 255, 1);
  min-width: 180px;
}

/* Change Date */

.ChangePickupDrop .ant-modal-content {
  border-radius: 0;
}

/* PickUpLocation Components  */
.PickUpLocation .location,
.DatepickerRange .location,
.LostAndFoundLocation .pageDropDown {
  min-height: 56px;
  cursor: pointer;
}

.PickUpLocation .locationData,
.LostAndFoundLocation .pageDropDownData,
.selectCountry .selectCountryList {
  position: absolute;
  width: 100%;
  border: 1px solid rgba(52, 58, 63, 0.3);
  box-shadow: 0px 6px 14px rgba(52, 58, 63, 0.08);
  display: none;
}

.PickUpLocation .locationData.is-active,
.LostAndFoundLocation .pageDropDownData.is-active,
.selectCountry .selectCountryList.is-active {
  display: block;
  z-index: 4;
}

.PickUpLocation .locationData .form-check-input,
.LostAndFoundLocation .pageDropDownData .form-check-input {
  visibility: hidden;
}

.PickUpLocation .locationData .locationLabel.is-active,
.selectCancelRide .custompopup.is-active > .locationLabel.is-active {
  color: var(--secondary);
}

.PickUpLocation .locationData .locationLabel.is-active svg * {
  fill: var(--secondary);
}

.selectCountry .selectCountrySec {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  background: #ffffff;
  min-height: 54px;
  border: 2.5px solid #ced4da;
  cursor: pointer;
}

.selectCountry .countryLabel.is-active {
  color: var(--secondary) !important;
}

.selectCountry .selectCountryList {
  height: 320px;
  overflow: scroll;
  /* width: 0px; */
  top: 60px;
}

.selectCountry .selectCountryList input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.selectCountry .selectCountrySec img {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(-50%, -50%);
}

.selectCountry .selectCountrySec > span > span {
  position: relative;
  left: -5px;
}

.selectCountry .countryLabel {
  display: flex !important;
  margin-bottom: 1rem;
}

.selectCountry .selectCountryList.is-active {
  display: flex !important;
  flex-direction: column;
  z-index: 4;
}

.selectCountry .countryLabel[for*="32"] {
  order: -1;
}

.selectCountry .countryLabel[for*="232"] {
  order: 0;
}

/* Change ICON For Date Picker */
.NewDatePicker .ant-picker-suffix {
  position: absolute;
  right: 6px;
  content: url(../../Assets/Images/DatePicker.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

/*  SearchCarDetailsList  */
.SearchCarDetailsList .checkAvailability {
  display: none;
}

.SearchCarDetails .PickUpDrop .carModelDetails img {
  display: none;
}

.SearchResult .SearchFilterCard .searchCarImageSection,
.SearchCarDetails .SearchFilterCard .searchCarImageSection,
.yourBookingViewDetials .searchCarImageSection,
.yourBookingRideList .searchCarImageSection,
.customerWriteAReview .searchCarImageSection,
.SelectedColorCarCard .searchCarImageSection,
.BookingConfirm .searchCarImageSection {
  aspect-ratio: 1 / 1;
}

.SearchResult .SearchFilterCard .searchCarImageSection > div,
.SearchCarDetails .SearchFilterCard .searchCarImageSection > div,
.BookingConfirm .searchCarImageSection > div,
.BookingEdit .searchCarImageSection > div,
.yourBookingViewDetials .searchCarImageSection > div,
.yourBookingRideList .searchCarImageSection > div {
  display: none;
}

.SearchResult .SearchFilterCard .searchCarImageSection > div:first-child,
.SearchCarDetails .SearchFilterCard .searchCarImageSection > div:first-child,
.BookingConfirm .searchCarImageSection > div:first-child,
.BookingEdit .searchCarImageSection > div:first-child,
.yourBookingViewDetials .searchCarImageSection > div:first-child,
.yourBookingRideList .searchCarImageSection > div:first-child {
  display: block;
}

.SearchResult .SearchFilterCard .searchCarImageSection img,
.SearchCarDetails .SearchFilterCard .searchCarImageSection img,
.BookingConfirm .searchCarImageSection img,
.yourBookingViewDetials .searchCarImageSection img,
.yourBookingRideList .searchCarImageSection img,
.customerWriteAReview .searchCarImageSection img,
.SelectedColorCarCard .searchCarImageSection img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.SelectedColorCarCard .searchCarImageSection img {
  position: absolute;
  left: 0;
  top: 0;
}

.SearchResult .SearchFilterCard .searchCarImageSection .ant-image-mask,
.SearchCarDetails .SearchFilterCard .searchCarImageSection .ant-image-mask,
.searchCarImageSection .ant-image-mask {
  border-radius: 10px;
}

/*  selectChildSeat Model Pop */
.selectChildSeat .selectNumberOfButton {
  column-gap: 0.5rem;
}

.selectChildSeat .seatSquareButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(29, 31, 62, 0.15);
}

.selectChildSeat .selectNumberOfButton .form-check-input[type="radio"],
.nmOfDriver .seatSquareButton .form-check-input[type="radio"] {
  height: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
  min-width: unset;
  min-height: unset;
}

.selectChildSeat .seatSquareButton:has(input:checked) {
  background: var(--primary-opacity);
}

/*  Yes No Button*/
.CustomerIndex .AdjustRental .YesNoButton {
  column-gap: 1rem;
}

.CustomerIndex .YesNoButton label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: rgba(29, 31, 62, 0.15);
}

.CustomerIndex .YesNoButton input {
  height: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
  min-width: unset;
  max-width: unset;
}

.CustomerIndex .YesNoButton .smallButton:has(input:checked) {
  background: var(--primary) !important;
  color: #fff;
}

/* Booking COnfirm */
.CustomerIndex .BookingConfirm .bookingModelPop svg {
  width: 70px;
}

.bookingPikerLocation img {
  margin-top: 1px;
}

.LargeButton.DownloadPDF {
  background: #d9d9d9;
  border: 1.5px solid #343a3f;
  color: #343a3f;
}

/* Booking Confirm Upload Doc */
body .BookingDocUpload .image-upload-grid .ant-upload-select {
  width: 100% !important;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  min-height: 250px;
  display: grid;
  place-items: center;
  width: 100% !important;
  height: 100% !important;
  padding: 1rem;
}

body .BookingDocUpload .afterUpload {
  min-height: 250px;
}

body .carEditPhotosUpload .afterUpload {
  min-height: 200px;
  border-radius: 12px;
  margin-bottom: 12px;
}

body .BookingDocUpload .image-upload-grid .ant-upload-list-item-container {
  width: 100% !important;
  border: 1px solid #e7e7e7;
  min-height: 250px;
  border-radius: 12px;
}

body .BookingDocUpload .image-upload-grid .ant-upload.ant-upload-select {
  border: 1px solid #e7e7e7 !important;
}

body .BookingDocUpload .image-upload-grid.documentUploaded .ant-upload.ant-upload-select,
body .BookingDocUpload .image-upload-grid.documentUploaded .ant-upload-list-item-done .ant-upload-list-item-actions a {
  display: none;
}

body .BookingDocUpload .ant-upload-list-item-actions {
  opacity: 0 !important;
  display: block;
  top: unset;
}

body .BookingDocUpload .ant-upload-list-item-done:hover .ant-upload-list-item-actions {
  opacity: 1 !important;
}

body .BookingDocUpload .ant-upload-list-item-action:before {
  content: "";
  display: none;
}

body .BookingDocUpload .ant-upload-list-item-action .anticon-delete {
  display: block;
}

body .BookingDocUpload .ant-upload-list-picture-card .ant-upload-list-item-done.ant-upload-list-item:hover::before {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.45) !important;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  left: 0;
}

body .BookingDocUpload .ant-upload-list-picture-card .ant-upload-list-item-done {
  border-style: solid !important;
}

/* Booking Active Ride */
.UploadCar .d-flex {
  gap: 1rem;
}

.UploadCar .image-upload-grid .ant-upload-select {
  width: 100% !important;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  min-height: 260px;
  min-width: 260px;
}

.UploadCar .image-upload-grid .ant-upload-list-item-container {
  width: 100% !important;
  border: 1px solid #bdbdbd;
  min-height: 250px;
  border-radius: 12px;
}

.UploadCar .image-upload-grid .ant-upload.ant-upload-select {
  border: 1px solid #e7e7e7 !important;
}

.UploadPhotoDescription textarea {
  min-height: 6rem;
  resize: none;
}

/* Up Coming Car Details  */
.SearchFilterCard .PriceHours {
  width: 6.875rem;
  height: 3.375rem;
  border-radius: 0.75rem;
  border: 1px solid rgb(52, 58, 63);
  margin-left: auto;
}

/* Up Coming Car Details Edit Car  */
.carPhotos .carPhotosButton,
.carPhotos .EditPhotos button {
  height: 46px;
  min-width: 150px;
}

.carPhotos .carPhotosButton input,
.pickupRadioStyle input {
  height: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
  min-width: unset;
  min-height: unset;
}

.carPhotos .carPhotosButton:has(input:checked) {
  background: var(--primary) !important;
  color: #fff;
}

.carPhotos .CarPhotosList {
  position: relative;
}

.carPhotos .EditUploadPhotos {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.CustomerIndex .ChangeBooking .YesNoButton {
  column-gap: 1rem;
}

.CustomerIndex .ChangeBooking .CustomerButton {
  height: 54px;
}

/* --- Pickup Drop Components --- */
.PickUpDrop .carModelDetails img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.PickUpDrop .displayLocation {
  padding-left: 10px;
}

.PickUpDrop .displayLocation .ant-steps-item-icon {
  border: 1.5px solid #343a3f !important;
  width: 15px !important;
  height: 15px !important;
  background: transparent;
  padding: 7.5px !important;
  position: relative;
  left: -3.75px !important;
}

.PickUpDrop .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #343a3f !important;
  margin-top: 4px;
}

.PickUpDrop .displayLocation .ant-steps-item-title {
  font-family: "ff_md";
  font-size: 14px;
  line-height: 1.5 !important;
  color: var(--dark) !important;
  padding-inline-end: 24px !important;
  display: block;
  word-wrap: break-word;
}

.PickUpDrop .displayLocation .ant-steps-item-description {
  font-family: "ff_rg";
  font-size: 14px;
  line-height: 18px;
  color: var(--light-md) !important;
}

.PickUpDrop .displayLocation .ant-steps-item-content {
  background: url("../../Assets/Images/PickUpDrop.svg") no-repeat;
  background-position: top right;
  position: relative;
  top: 10px;
}

/* .PickUpDrop .displayLocation .ant-steps-item:first-child {
  min-height: 100px;
} */

/* Home Page */
/* Banner Section */
.CustomerIndex .homePage .banner_section {
  background: rgba(240, 247, 251, 1);
  height: 100%;
  padding-top: 70px;
}

.CustomerIndex .homePage .banner_section_background {
  background: url("../../Assets/BannerImage/home-banner.png") no-repeat;
  height: auto;
  background-size: 450px;
  background-position: right center;
  padding: 40px 0 50px 0;
}

/* How car rental works */
.carRentalWorkItemBackgroundStyle * {
  transition: all 0.25s ease;
  box-shadow: none;
}
.carRentalWorkItemBackgroundStyle:hover {
  box-shadow: 0px 12px 20px 0px rgba(223, 227, 230, 1);
}

.carRentalWorkItemBackgroundStyle:hover .carRentalWorkItemDesc {
  background-color: var(--primary) !important;
}

.carRentalWorkItemBackgroundStyle:hover .carRentalWorkItemDesc p {
  color: var(--secondary) !important;
}

.carRentalWorkItemBackgroundStyle:hover .carRentalWorkItemDesc > div {
  color: var(--light);
}

.carRentalWorkImage {
  min-height: 12.5rem;
  height: 100%;
  object-fit: cover;
}

/* Meet some of our cars */
.MeetSomeMoreCarBackground {
  --ang: -1deg;
  position: relative;
  transition: all 0.25s ease 0s;
}

.MeetSomeMoreCarBackground:after {
  content: "";
  background: rgba(245, 247, 249, 1);
  height: 100%;
  display: block;
  background: linear-gradient(var(--ang, 185deg), rgba(245, 247, 249, 1) calc(90% - 0.5px), #fff calc(50% + 0.5px));
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
}

.MeetSomeMoreCarBackground img {
  max-height: 200px;
  margin: 0 auto;
  height: 150px;
}

.MeetSomeMoreCarBackground .slick-prev {
  left: 0;
  z-index: 9;
}

.MeetSomeMoreCarBackground .slick-next,
.PickUpSlider .slick-next {
  right: 0;
  z-index: 9;
}

.MeetSomeMoreCarBackground .slick-list,
.PickUpSlider .slick-list {
  margin: 0 -50px;
}

.MeetSomeMoreCarBackground .slick-list {
  overflow: visible;
}

.MeetSomeMoreCarBackground .slick-slide .homecheckavailability {
  display: none;
}

.MeetSomeMoreCarBackground .slick-slide.slick-active.slick-current .homecheckavailability {
  display: block;
}

.MeetSomeMoreCarBackground .slick-slide.slick-active {
  transform: scale(0.8);
  transition: all 0.25s ease;
}

.MeetSomeMoreCarBackground .slick-slide.slick-active.slick-current {
  transform: scale(1.1);
}

.MeetSomeMoreCarBackground .MeetSomeMoreCarSliderContent {
  display: none;
}

.MeetSomeMoreCarBackground .slick-slide.slick-active.slick-current .MeetSomeMoreCarSliderContent {
  display: block;
}

.MeetSomeMoreCarBackground .SamplePrevArrow {
  left: calc(35%) !important;
  top: calc(50% + 2rem);
  border: 1px solid black;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.MeetSomeMoreCarBackground .SampleNextArrow {
  right: calc(35%) !important;
  top: calc(50% + 2rem);
  border: 1px solid black;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.MeetSomeMoreCarBackground .SampleNextArrow svg,
.PickUpSlider .SampleNextArrow svg {
  transform: rotate(180deg);
}

.MeetSomeMoreCarBackground .SamplePrevArrow:focus,
.MeetSomeMoreCarBackground .SampleNextArrow:focus,
.MeetSomeMoreCarBackground .SamplePrevArrow:hover,
.MeetSomeMoreCarBackground .SampleNextArrow:hover,
.PickUpSlider .SamplePrevArrow:focus,
.PickUpSlider .SampleNextArrow:focus,
.PickUpSlider .SamplePrevArrow:hover,
.PickUpSlider .SampleNextArrow:hover {
  background: var(--primary);
}

.MeetSomeMoreCarBackground .SamplePrevArrow:focus *,
.MeetSomeMoreCarBackground .SampleNextArrow:focus *,
.MeetSomeMoreCarBackground .SamplePrevArrow:hover *,
.MeetSomeMoreCarBackground .SampleNextArrow:hover *,
.PickUpSlider .SamplePrevArrow:focus *,
.PickUpSlider .SampleNextArrow:focus *,
.PickUpSlider .SamplePrevArrow:hover *,
.PickUpSlider .SampleNextArrow:hover * {
  stroke: #fff !important;
}

/* Pick-up/ bring back Locations */
.PickupStyle {
  overflow: hidden;
}
.PickUpSlider .PickupItem {
  border: 1px solid #c4ccd2;
  filter: drop-shadow(0px 0.375 0.875 rgba(14, 20, 24, 0.08));
}

.PickUpSlider .PickupItem .PickupDescription button {
  max-width: 100px;
}

.PickUpSlider .swiper-slide-next * {
  transition: all 0.25s ease;
}

.PickUpSlider .swiper-slide-next .PickupItem {
  background: var(--primary);
}

.PickUpSlider .swiper-slide-next .PickupDescription p {
  color: rgba(255, 255, 255, 1);
}

.PickUpSlider .swiper-slide-next .PickupDescription button {
  color: var(--light);
  background: var(--secondary) !important;
}

/* .PickUpSlider .PickupItem .PickupMap > div,
.PickUpSlider .PickupItem .PickupMap, */
.PickUpSlider .PickupItem .PickupMap #map {
  aspect-ratio: 1/1;
  min-height: 135px;
  min-width: 135px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  max-width: 135px;
  max-height: 135px;
}

.PickUpSlider .PickupItem .PickupMap #map {
  border-radius: 8px;
}

.PickUpSlider .SamplePrevArrow {
  top: calc(100% + 2rem);
  border: 1px solid black;
  border-radius: 50%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  left: calc(47.5%) !important;
  display: flex !important;
}

.PickUpSlider .SampleNextArrow {
  top: calc(100% + 2rem);
  border: 1px solid black;
  border-radius: 50%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  right: calc(47.5%) !important;
  display: flex !important;
}

.PickupStyle .pickupRadioStyle {
  max-width: 600px;
  width: 100%;
}

.PickupStyle .pickupRadioStyle > div {
  display: flex;
}

.PickUpSlider .PickupItem > div {
  min-height: 135px;
  max-height: 156px;
}

.PickUpSlider .PickupItem .PickupDescription p {
  text-overflow: ellipsis;
}

.PickUpSlider .homePageLocation .swiper-wrapper {
  padding-bottom: 40px;
}

.homePageLocation .swiper-button-prev,
.homePageLocation .swiper-button-next {
  height: 32px;
  width: 32px;
  background-color: transparent;
  border-radius: 50%;
  top: unset;
  bottom: 0;
  border: 1px solid var(--primary);
}

.homePageLocation .swiper-button-next {
  right: calc(50% - 42px) !important;
}

.homePageLocation .swiper-button-prev {
  left: calc(50% - 42px) !important;
}

.homePageLocation .swiper-button-next:after,
.homePageLocation .swiper-button-prev:after {
  color: var(--primary);
}
/* Promo Banner Image */
.PromoBanner .PromoBannerImage {
  background: url(../../Assets/Images/Promo-offer.png);
  background-size: cover;
  background-position: center;
}

/* Home Page End */
/* Your Booking */
.WriteAReview .ant-rate *,
.WriteAReview .ant-rate {
  font-size: 16px;
}
/* Your Booking End*/

/* Your Booking Process Upcoming Active Ride Click */
.CustomerIndex .UpcomingStartRide .yourBookingRideList .RideCancelModify {
  display: none !important;
}

.CustomerIndex .UpcomingStartRide .image-upload-grid .ant-upload-select {
  width: 100% !important;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  min-height: 260px;
  min-width: 260px;
}

/* Your Booking Process Change Booking */
.SearchYesNo,
.BookingSelectDay,
.ChildSeat,
.NumberDriver {
  min-height: 200px !important;
}

/* Static Pages */
/* Career */
.CareerPage > .careersBanner {
  width: 100%;
  height: 100vh;
  background: rgba(174, 178, 183, 1);
}

.CareerPage .careerResume input {
  display: none;
}

.CareerPage .careerResume .careerResumeStyle {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(172, 176, 180, 1)' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 12px;
}

.careerResume .ant-upload-list .ant-upload-list-item-progress,
.careerResume .ant-upload-list .ant-upload-list-item-actions,
.careerResume .ant-fade-leave-active,
.careerResume .ant-progress-line {
  margin-top: 12px;
}

.careerResume .ant-upload-list-item-thumbnail.ant-upload-list-item-file {
  display: flex;
  align-items: center;
  justify-content: center;
}

.careerResume .ant-upload-list-item-actions.picture {
  margin-top: 0;
}

.careerResume button.ant-btn.css-dev-only-do-not-override-12upa3x.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-action,
.careerResume .ant-upload-list-item-actions.picture {
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Career End*/
/* Location Page */
.CustomerIndex .OurLocation .OurLocationBanner:before,
.CustomerIndex .CareerPage .CareerPageBanner:before,
.CustomerIndex .CarRental .CarRentalBanner:before {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(4deg, #000000 0%, rgba(0, 0, 0, 0.1) 58.33%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 100%;
}

.OurLocation .ourLocationBanner {
  z-index: 1;
  position: relative;
}

.OurLocation .OurLocationList {
  margin-top: -200px;
  position: relative;
  z-index: 1;
}

.OurLocation .locationItem > div {
  box-shadow: 0px 4px 20px rgba(39, 39, 39, 0.15);
}

.OurLocation .locationItemImage {
  width: 100%;
  height: 280px;
  display: grid;
  place-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: rgba(245, 247, 247, 1);
  overflow: hidden;
}

.OurLocation .locationItemImage img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.OurLocation .locationItemDescription {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.OurLocationCarList img {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
/* Location Page End*/

/*  Price Specification */
.PriceSpecification button img {
  height: 32px;
}

.PriceSpecification .priceSpecificationHeading {
  top: -5px;
}

.PriceSpecification p + p {
  margin-top: 0;
}

/* Customer Profile */

.customerProfile .customerProfileAvatarBox {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.customerProfile .customerProfileAvatarImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 180px;
  height: 180px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
}

.customerProfile .customerProfileAvatarImage img {
  width: 100%;
  height: 100%;
  text-align: center;
  /* object-fit: cover; */
  color: transparent;
  text-indent: 10000px;
}

.customerProfile .customerProfileAvatarImage.is-active img {
  object-fit: cover;
  border: 1px solid var(--primary-opacity);
  border-radius: 50%;
}

.customerProfile .customerProfileAvatarChange {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  min-width: 42px;
  line-height: 1;
  padding: 0 6px;
  height: 42px;
  border-radius: 50%;
  z-index: 1;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  bottom: 14%;
  right: 14%;
  transform: scale(1) translate(50%, 50%);
  transform-origin: 100% 100%;
  background-color: #fff;
  box-shadow: 0px 6px 5px 0px rgba(52, 58, 63, 0.09);
}

.customerProfile .customerProfileUpload .ant-upload-list {
  display: none;
}

.CustomerIndex .customerProfile .CustomerInput .form-control,
.CustomerIndex .customerProfile .birthDateField .form-input-type {
  border-radius: 0.375rem;
}

.CustomerIndex .customerProfile .CustomerInput .form-control {
  border-color: var(--primary-opacity);
}

.customerProfile .managePersonalInfo .ManageProfileLabel {
  color: var(--light-md);
}

.customerProfile .managePersonalInfo .input-group button {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-right: 0px transparent !important;
  background: var(--primary-opacity) !important;
  opacity: 1 !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.customerProfile .managePersonalInfo .input-group input {
  font-family: ff_md;
  font-size: 0.938rem;
  line-height: 1.25rem;
  color: rgb(23, 23, 23);
  height: 3.375rem;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left: 0px !important;
  background: var(--primary-opacity) !important;
  margin-left: 0 !important;
}

.customerProfile .managePersonalInfo .col-12:has(input:focus) button {
  border-color: rgb(0, 0, 0) !important;
  background: transparent !important;
}

.customerProfile .managePersonalInfo .col-12:has(input:focus) input {
  background: transparent !important;
}

.customerProfile .managePersonalInfo .col-12:has(input:focus) input {
  border-color: rgb(0, 0, 0) !important;
}

.customerProfile .managePersonalInfo .col-12:has(input:focus) .ManageProfileLabel {
  color: var(--dark);
}

.customerProfile .managePersonalInfo .profileBirthDay:has(input:focus) input {
  border-color: transparent !important;
}

.customerProfile .managePersonalInfo .col-12:has(input) button svg [fill="#ACB0B4"],
.customerProfile .managePersonalInfo .col-12:has(input) button svg [fill="#171717"],
.customerProfile .managePersonalInfo .col-12:has(input) button svg [fill="var(--primary)"] {
  fill: rgba(172, 176, 180, 1);
}

.customerProfile .managePersonalInfo .col-12:has(input:focus) button svg [fill="#ACB0B4"],
.customerProfile .managePersonalInfo .col-12:has(input:focus) button svg [fill="#171717"],
.customerProfile .managePersonalInfo .col-12:has(input:focus) button svg [fill="var(--primary)"] {
  fill: rgb(39, 39, 39);
}

.customerProfile .managePersonalInfo .input-group input:focus {
  box-shadow: none;
  border-color: rgb(52, 58, 63) !important;
}

.customerProfile .profileBirthDate {
  height: 3.375rem;
  background: rgb(245, 247, 247) !important;
  border-color: transparent;
}

.customerProfile .profileBirthDate .ant-picker-input .ant-picker-suffix .anticon-calendar > svg {
  width: 20px;
  height: 20px;
}

.customerProfile .profileBirthDate .ant-picker-input {
  flex-direction: row-reverse;
}

.customerProfile .profileBirthDate .ant-picker-input .ant-picker-suffix {
  margin-left: 0;
  padding: 6px 12px;
}

.customerProfile .profileBirthDate .ant-picker-input .ant-picker-input input {
  padding: 0.375rem 0.75rem !important;
}

.customerProfile .profileBirthDate.ant-picker:hover,
.customerProfile .profileBirthDate.ant-picker-focused {
  border-color: transparent;
  border-inline-end-width: 1px;
  box-shadow: none !important;
}

.customerProfile .profileBirthDate .ant-picker-input .ant-picker-clear {
  right: 15px;
}

.customerProfile .profileBirthDate.ant-picker-focused {
  border-color: #000 !important;
}

.customerProfile .profileBirthDate.ant-picker-focused .ant-picker-suffix svg {
  fill: #000;
}

.customerProfile .profileBirthDay input,
.customerProfile .ant-picker .ant-picker-input {
  height: 100%;
}

.customerProfile .ant-picker:hover,
.customerProfile .ant-picker-focused,
.customerProfile .profileBirthDay input {
  border-color: transparent;
  box-shadow: none;
}

.CustomerIndex .customerProfile .ant-picker-focused {
  border: 1px solid var(--primary) !important;
  background-color: transparent !important;
}

/* Write a review */
.customerWriteAReview .ant-modal-content {
  border-radius: 0;
  padding: 0;
}

.customerWriteAReview .WriteAReview > div {
  min-height: 90px;
}

.customerWriteAReview .WriteAReview .WriteAReviewCarInfo {
  background: #f5f7f7;
}

.customerWriteAReview .WriteAReview .WriteAReviewCarInfo img {
  height: 140px;
  width: 140px;
}

.ReviewAvatar img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  /* border: 1px solid rgba(217, 217, 217, 0.5); */
}

.customerWriteAReview .WriteAReview .customerReviewList {
  border-top: 1px solid rgba(217, 217, 217, 0.5);
}

.customerWriteAReview .WriteAReview .customerReviewList:first-child {
  border-top: 1px solid transparent;
}

/* Adjust Rental Processbar Responsive */
.BookingIndex .Progressbar .bookingProcessbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.bookingProcessbar .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
  border-color: transparent !important;
}

.bookingProcessbar .ant-steps-item-container {
  background: rgba(0, 0, 0, 0) !important;
}

.BookingIndex .Progressbar .bookingProcessbar .ant-steps-item-finish .ant-steps-item-tail {
  width: 100%;
}

.Progressbar .processbar .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after {
  background-color: rgba(52, 58, 63, 1) !important;
  width: 100%;
  left: 0;
  position: relative;
}

.BookingIndex .Progressbar .bookingProcessbar .ant-steps-item:nth-child(2) .ant-steps-item-container .ant-steps-item-tail::after {
  left: 50%;
}

/* Banner */
.homePage .ant-picker-input input::placeholder,
.homePage .ant-picker-input input,
.birthInputField .ant-picker-input input,
.birthInputField .ant-picker-input input::placeholder {
  color: var(--dark);
  opacity: 1;
  font-family: "ff_md";
  font-size: 14px;
  line-height: 18px;
  background-color: transparent;
}

/* Booking Confirm */
.customerBookingConfirmDocument {
  max-height: 250px;
  min-height: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.customerBookingConfirmDocument input {
  display: none;
}

.CustomerIndex .HowItWorks .customerFaqCard > div:last-child {
  border-bottom: none;
}

.SearchPriceDetails p + p,
.PickUpDrop p + p,
.SearchCarDetails p + p {
  margin-top: unset;
}

/* Static Pages */
.AboutUsStyle {
  background: url(../../Assets/BannerImage/about-banner.jpg);
  background-size: cover;
  background-position: 25% 25%;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

.AboutUsStyle::before,
.CareerPageBanner::before {
  content: "";
  display: block;
  background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.1) 58.33%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  height: 500px;
  width: 100%;
  bottom: 0;
}

.AboutUsStyle .HeadingStyle_heading,
.AboutUsStyle .HeadingStyle_headingdescription {
  color: #ffffff;
}

.AboutUsStyle .AboutUsDescription {
  font-family: "ff_rg";
  font-size: 1rem;
  line-height: 1.75rem;
  color: #343a3f;
}

.AboutUsBox h4 {
  font-family: "ff_bd";
  font-size: 2rem;
  line-height: 2.625rem;
  color: #ffffff;
}

.AboutUsBox p {
  font-family: "ff_rg";
  font-size: 1rem;
  line-height: 1.313rem;
  color: #ffffff;
}

.AboutUsBox .AboutUsBoxLeft {
  min-height: 25.5rem;
  background: url(../../Assets/BannerImage/about-banner-section.jpg);
  background-size: cover;
  background-position: 25% 25%;
  background-repeat: no-repeat;
}

.AboutUsBox .AboutUsBoxRight {
  margin-left: -2rem;
  min-height: 17.5rem;
}

/* Help Page */
.HelpItemVector {
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
}

/* How it work */
.HowItWorks {
  width: 100%;
  height: 100%;
}

.HowItWorkListLabel,
.customerHelp .f38,
.RentalInformation .container > .f38 {
  font-family: "ff_bd";
  font-size: 2.125rem;
  line-height: 2.75rem;
  color: var(--primary);
}

.howitwork .circle {
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
  background-color: var(--primary);
  border-radius: 50%;
}

.HowItWorkList h2 {
  font-family: "ff_bd";
  font-size: 3.125rem;
  line-height: 4.063rem;
  color: #343a3f;
  opacity: 0.1;
}

.HowItWorkList h4 {
  font-family: "ff_bd";
  font-size: 1.188rem;
  line-height: 1.563rem;
}

.HowItWorkList p {
  font-family: "ff_rg";
  font-size: 1rem;
  line-height: 1.313rem;
  color: #343a3f;
}

.HowItWorkList img {
  width: 1.75rem;
}

/* Help */
.Help .HelpShowArticle h6 {
  font-family: "ff_bd";
  font-size: 1.125rem;
  line-height: 1.438rem;
  color: #343a3f;
}

.HelpItem .HelpItemHeading {
  min-height: 3.875rem;
}

.HelpItem p {
  font-family: "ff_md";
  font-size: 1rem;
  line-height: 1.313rem;
  color: #717579;
}

.HelpItem .HelpItemContent .helpArticleList p:last-child hr {
  display: none;
}

.customerFaqCard .customerFaqItems:last-child {
  border-bottom: 0 !important;
}

.customerFaqStyle .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
  padding: 2rem 0;
  border-radius: 0 !important;
}

.customerFaqStyle .ant-collapse-header {
  padding: 0rem !important;
}

.customerFaqStyle .ant-collapse-header-text {
  font-family: "ff_bd";
  font-size: 19px;
  line-height: 25px;
  color: #343a3f;
}

.customerFaqStyle .ant-collapse-content-box {
  font-family: "ff_rg";
  font-size: 1.063rem;
  color: #717579;
  padding-left: 38px !important;
  padding-bottom: 0 !important;
}

.customerFaqStyle .ant-collapse-content-box p {
  margin-bottom: 0;
}

/* Our Location */
.CustomerIndex .OurLocation .OurLocationBanner {
  background: url("../../Assets/BannerImage/ourLocation.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

/* Career */
.CareerPageBanner {
  background: url(../../Assets/Images/Career/CareerBanner.png);
  background-size: cover;
  background-position: 25% 25%;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

.CarRental .CarRentalBanner.CarRentalDealsBanner {
  background: url(../../Assets/BannerImage/carRentalDeal.jpg);
}

/* Car Rental */
.CarRental .CarRentalBanner {
  background: url(../../Assets/Images/CarRental/car-rental.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

.CarRental .carLocationCard {
  min-height: 115px;
}

.CarRental .carLocationCard > div,
.CarRental .carLocationCard p {
  transition: ease 0.25s;
}

.CarRental .carLocationCard:hover > div {
  background-color: var(--primary);
}

.CarRental .carLocationCard:hover p {
  color: #fff;
}

.CarRental .carLocationCard:hover svg [fill="var(--primary)"] {
  fill: #fff;
}

.carRentalMostPopularCarousel .slick-prev {
  width: auto;
  height: auto;
  inset-inline-start: -40px;
  z-index: 1;
}

.carRentalMostPopular .slick-prev {
  left: -15px;
  z-index: 1;
}

.carRentalMostPopular .slick-next {
  right: 40px;
  z-index: 1;
}

.carRentalMostPopular .slick-prev svg,
.carRentalMostPopular .slick-next svg {
  width: 30px;
}

.carRentalMostPopular .slick-list {
  padding-bottom: 100px !important;
}

.carRentalMostPopular .slick-slide .checkAvailability {
  display: none;
}

.carRentalMostPopular .slick-slide.slick-center .checkAvailability {
  display: block;
}

.carRentalMostPopular .checkAvailability {
  position: absolute;
  width: 100%;
  bottom: -70px;
  left: 0;
}

.featuredCarTag {
  z-index: 1;
  background: #0ba966;
  border-radius: 6px 0px 10px 0px;
}

/* Trello Error Customer */
.PromoCode .promoCodeInput button:disabled {
  opacity: 0.5;
}

/* Loader */
.FullScreenLoader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.CustomerIndex input[type="number"]::-webkit-inner-spin-button,
.CustomerIndex input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.CustomerIndex .DriverDetails .CustomCheckBox .form-check-input[type="checkbox"] {
  font-size: 22px;
  border-radius: 4px;
}

/* Map */
.CustomerIndex .PickupMap .gmnoprint,
.CustomerIndex .PickupMap .gm-style-cc {
  display: none;
}

.CustomerIndex .PickupMap button.gm-control-active.gm-fullscreen-control {
  width: 30px !important;
  height: 30px !important;
}

.CustomerIndex .PickupMap button.gm-control-active.gm-fullscreen-control {
  display: none;
}

.PickUpSlider .slick-slide.slick-active.slick-current button.gm-control-active.gm-fullscreen-control {
  display: block;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input:focus {
  border-color: var(--primary);
}

/*  Adjustment */
.selectChildSeat .selectAOption {
  display: grid !important;
  grid-template-columns: 2fr 1fr 1fr !important;
  align-items: center;
}

/* Add to calender */
.AddToCalendarButton {
  margin-left: -5px;
}

/* Notification */
.CustomerIndex .customerNotification .customerNotificationBody .NotificationCard {
  border-bottom: 1px solid var(--light-md);
  border-top: 1px solid var(--light-md);
}

.CustomerIndex .customerNotification .customerNotificationBody .NotificationCard:first-child {
  border-top: 1px solid transparent;
}

.CustomerIndex .customerNotification .customerNotificationBody .NotificationCard:last-child {
  border-bottom: 1px solid transparent;
}

.CustomerIndex .customerNotification .customerNotificationBody .NotificationCard:hover {
  border-color: transparent;
  background: var(--primary-opacity);
  border-radius: 6px;
}

.CustomerIndex .customerNotification .customerNotificationBody .notificationTime {
  width: auto !important;
  right: 0;
  top: 10px;
}

.notificationIcon svg {
  width: 30px;
}
/* Notification End*/

/* Custom Date Time Raange Picker Starts */
.date-time-range-picker-modal .time-picker ul::-webkit-scrollbar-thumb {
  background-color: var(--primary-opacity) !important;
}
.ant-picker.ant-picker-range.w-100.date-time-range-picker {
  background-color: transparent;
  border: none !important;
}
.ant-picker.ant-picker-range.w-100.date-time-range-picker.ant-picker-focused {
  box-shadow: none;
}
.date-time-range-picker-modal .ant-modal-content {
  height: calc(100% - 42px) !important;
  display: flex;
  flex-flow: column;
}
.date-time-range-picker-modal.hasError .ant-modal-content {
  height: 100% !important;
}
.date-time-range-picker-modal .ant-picker.custom-date-time-picker {
  visibility: hidden;
  height: 0;
  width: 0;
}
.date-time-range-picker-modal .time-picker ul {
  min-height: calc(100% - 32px);
  max-height: calc(100% - 32px);
  min-width: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 5px 20px;
  padding-left: 0;
}
.date-time-range-picker-modal .time-picker ul li {
  list-style-type: none;
  position: relative;
  width: 100%;
  text-align: center;
}
.date-time-range-picker-modal .time-picker p {
  margin: 5px 10px;
  text-align: center;
}
.date-time-range-picker-modal .time-picker ul label {
  display: flex;
  justify-content: center;
  min-width: 50px;
  padding: 5px 10px;
  font-size: 16px;
  width: 100%;
}
.date-time-range-picker-modal .time-picker ul input:checked + label {
  /* background-color: #d2e4f380; */
  background-color: var(--secondary);
  color: #fff;
}
.date-time-range-picker-modal .time-picker {
  display: inline-flex;
  justify-content: center;
  padding: 8px 0;
  width: 100%;
  max-height: 325px;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
}
.date-time-range-picker-modal .ant-modal-footer {
  margin-top: auto;
}
.custom-date-time-picker-popup .ant-picker-panel-container {
  box-shadow: none;
}
.date-time-range-picker-modal .calendar-next-arrow {
  transform: rotate(180deg);
}

.date-time-range-picker-modal .calendar-arrow-btn {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Custom Date Time Raange Picker Ends */

/* Booking List */
.BookingList .CarImageDescriptionSection .BookingListDisplayLocation .ant-steps-item-tail::after {
  margin-inline-start: 3.5px !important;
}

.CustomerIndex .BookingList .yourBookingRideList .HourMaxwidth,
.SearchCarDetails .SearchFilterCard .HourMaxwidth,
.BookingEdit .yourBookingRideList .HourMaxwidth {
  max-width: 115px;
  min-width: 115px;
}

.customerCarReview .customerReviewList:not(:last-child) {
  border-bottom: 1px solid var(--primary-opacity);
}

.MostPopularCar:hover > div {
  background-color: var(--primary-opacity);
}

.careerDirectMail a:hover {
  color: var(--secondary) !important;
}

.CustomerIndex .position-top-1 {
  position: relative;
  top: 1px;
}

/* Aspect ratio Safari Issue*/
.SearchResult .SearchFilterCard .searchCarImageSection,
.SearchCarDetails .SearchFilterCard .searchCarImageSection,
.yourBookingViewDetials .searchCarImageSection,
.yourBookingRideList .searchCarImageSection,
.customerWriteAReview .searchCarImageSection,
.SelectedColorCarCard .searchCarImageSection,
.BookingConfirm .searchCarImageSection {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.SearchResult .SearchFilterCard .searchCarImageSection > div:first-child,
.SearchResult .SearchFilterCard .searchCarImageSection > img:first-child,
.SearchCarDetails .SearchFilterCard .searchCarImageSection > div:first-child,
.SearchCarDetails .SearchFilterCard .searchCarImageSection > img:first-child,
.BookingConfirm .searchCarImageSection > div:first-child,
.BookingConfirm .searchCarImageSection > img:first-child,
.BookingEdit .searchCarImageSection > div:first-child,
.BookingEdit .searchCarImageSection > img:first-child,
.yourBookingViewDetials .searchCarImageSection > div:first-child,
.yourBookingViewDetials .searchCarImageSection > img:first-child,
.yourBookingRideList .searchCarImageSection > div:first-child,
.yourBookingRideList .searchCarImageSection > img:first-child {
  position: absolute;
  top: 0;
}
/* Aspect ratio Safari Issue End */

.ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-action {
  background-color: transparent;
}

.CustomerIndex .form-check-input {
  min-width: 22px;
  min-height: 22px;
}

/* customerModalTitle */
.customerAntModal .ant-modal-body .customerModalTitle {
  font-size: 24px;
  line-height: 24px;
  font-family: "ff_bd";
  margin: 1rem 0;
  text-align: center;
  color: var(--primary);
}

.custom-booking-loader {
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 992px) {
  .custom-booking-loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9;
  }
}

.CustomerIndex .searchFilter {
  position: sticky;
  top: 80px;
  max-height: fit-content;
  /* overflow-y: scroll; */
}

.CustomerIndex .protectRental .display {
  flex-direction: column;
  justify-content: start !important;
}

.CustomerIndex .protectRental .display p {
  width: calc(100% - 0.5rem);
}
.CustomerIndex .protectRental .display p + p {
  margin-top: 0.5rem !important;
}

/* Swiper For Search Detail Page */
.SearchCarDetails .SearchCarDetailsList .swiper-button-next,
.SearchCarDetails .SearchCarDetailsList .swiper-button-prev,
.fleet-overview .swiper-button-next,
.fleet-overview .swiper-button-prev,
.CarRental .swiper-button-next,
.CarRental .swiper-button-prev {
  height: 32px;
  width: 32px;
  background-color: var(--secondary);
  border-radius: 50%;
}

.SearchCarDetails .SearchCarDetailsList .swiper-button-next:after,
.SearchCarDetails .SearchCarDetailsList .swiper-button-prev:after,
.fleet-overview .swiper-button-next::after,
.fleet-overview .swiper-button-prev::after,
.CarRental .swiper-button-next::after,
.CarRental .swiper-button-prev::after {
  font-size: 16px;
  color: #fff;
}

.NewsLetterForm input,
.NewsLetterForm textarea {
  border-color: transparent;
}

.customerNotificationBody {
  max-height: 70vh;
  overflow-y: scroll;
  border: 1px solid var(--primary-opacity);
  border-radius: 12px;
}

.CustomerIndex .SearchCarDetails,
.CustomerIndex .SearchResult,
.CustomerIndex .BookingIndex,
.CustomerIndex .BookingConfirm,
.CustomerIndex .BookingFailure,
.OurLocation,
.BookingList,
.yourBookingViewDetials,
.BookingEdit,
.SearchResult,
.SearchCarDetails,
.BookingIndex,
.customerProfile,
.HowItWorks,
.customerHelp,
.CustomerIndex .OurLocation,
.CareerPage,
.RentalInformation,
.LostAndFound,
.CarRental,
.pt-70 {
  padding-top: 70px;
}

.drivingIdentification img {
  height: 250px !important;
  object-fit: cover;
  width: 100%;
}

/* Hide Upload Component While Displaying Progress Bar */
.BookingDocUpload .ant-upload-list-item-container + .ant-upload.ant-upload-select,
.carEditPhotosModel .ant-upload-list-item-container + .ant-upload.ant-upload-select {
  display: none !important;
}

.BookingDocUpload .ant-image .ant-image-mask,
.carEditPhotosUpload .ant-image .ant-image-mask {
  width: 100%;
  border-radius: 8px;
  min-height: 200px;
}

.BookingDocUpload .ant-image,
.BookingDocUpload .ant-image img,
.carEditPhotosUpload .ant-image,
.carEditPhotosUpload .ant-image img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
  max-height: 250px;
  position: absolute;
  left: 0;
  top: 0;
}

.BookingDocUpload .remove-img-btn,
.carEditPhotosUpload .remove-img-btn {
  z-index: 9;
  right: 1rem;
  top: 1rem;
  height: 25px;
  width: 25px;
}

.nmOfDriver [for="Extra Drivers0"] {
  min-width: max-content;
}

.nmofdrivercol {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.france .nmofdrivercol {
  font-size: 12px;
}

.svgIconSize svg {
  min-width: 24px;
  min-height: 24px;
}

.adminHelpCategory .CustomerInput label,
.adminHelpCategory .CustomerInput p {
}

.careerResume .ant-upload.ant-upload-select {
  width: 100%;
}

.beforeDots {
  list-style: url(../../Assets/ICONS/bullets.png);
}

.dealImage {
  opacity: 0;
}

.dealImageZIndex {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-15%, -40%);
  max-width: 145px;
}

.dealOffer {
  position: absolute;
  width: 75px;
  height: 75px;
  left: 1rem;
  top: 1rem;
  border-radius: 50%;
  background: #fff;
  display: grid;
  align-items: center;
  text-align: center;
  cursor: default;
}

.Promocodedeal .swiper-slide,
.carRentalMostPopular .swiper-slide {
  height: auto;
}
