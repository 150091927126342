.header#header {
  position: fixed;
  z-index: 99;
  min-height: 70px;
  box-shadow: 0px 6px 14px rgba(14, 52, 75, 0.25);
}

.header .header-logo img {
  width: 90px;
}

.header .header-menubar .menu-navigationbar {
  column-gap: 1rem;
}

.header .header-menubar .menu-navigationbar li a {
  min-width: 80px;
  height: 40px;
  background: transparent;
  border-radius: 12px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  transition: all 0.25s ease;
}

.header .header-menubar .menu-navigationbar li a.active,
.header .header-menubar .menu-navigationbar li a:hover {
  opacity: 100% !important;
}

.header .header-menubar .menu-navigationbar li a.active:after {
  content: "";
  width: 20px;
  height: 7px;
  position: absolute;
  background-color: var(--secondary);
  border-radius: 12px;
  bottom: 0;
}

.header .header-menubar .headerLaunguage,
.header .header-menubar .headerHandler {
  position: relative;
}

.header .header-menubar .headerLaunguage button {
  height: 36px;
  min-width: 110px;
}

.header .header-menubar .headerLaunguage ul,
.header .header-menubar .headerAccount ul {
  position: absolute;
  right: 0;
  top: 45px;
  box-shadow: 0px 6px 14px rgba(52, 58, 63, 0.08);
  min-width: max-content;
  display: none;
}

.header .header-menubar .headerAccount ul {
}

.header .header-menubar .headerLaunguage ul li input[type="radio"] {
  width: 14px;
  height: 14px;
  accent-color: var(--primary);
  margin-top: 0;
}

.header .header-menubar .headerLaunguage ul li input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.header .header-menubar .headerLaunguage ul li,
.header .header-menubar .headerAccount ul li a {
  line-height: 40px;
}

.header .header-menubar .headerAccount ul li a {
  color: rgba(39, 39, 39, 1);
}

.header .header-menubar .headerLaunguage.is-active ul,
.header .header-menubar .headerAccount.is-active ul {
  display: block;
}

.header .header-menubar .headerHandler .headerlogIn,
.header .header-menubar .headerAccount .userAvatar,
.header .header-menubar .headerHandler .toggleButton {
  height: 36px;
}

.header .header-menubar .headerAccount .userAvatar {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  object-fit: cover;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
}

/*  AntD */
.CustomerheaderModel .header {
  min-height: 90px;
  border-bottom: 1px solid #d9d9d9;
}

.CustomerheaderModel .ant-modal-content {
  border-radius: 0;
  padding: 0;
  background-color: var(--light);
}

.CustomerheaderModel .mobileCircle {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background: rgba(14, 52, 75, 0.1);
}

.CustomerheaderModel .customerMenubar a.active .mobileCircle {
  background: var(--primary);
}

.CustomerheaderModel .customerMenubar a.active .mobileCircle svg [fill="#343A3F"] {
  fill: #fff;
}

.CustomerheaderModel .customerMenubar a.active .mobileCircle svg [stroke="#343A3F"] {
  stroke: #fff;
}

.CustomerheaderModel .ant-modal-content .customerMenubar a {
  display: flex;
  align-items: center;
}

.adminMenubar .NavIconBG {
  width: 32px;
  height: 32px;
  background: rgba(52, 58, 63, 0.05);
  border-radius: 6px;
}

.adminMenubar .NavigationLink.active .NavIconBG {
  background: var(--primary);
}

.adminMenubar .NavigationLink.active .NavIconBG svg [fill="#0e344b"] {
  fill: #fff;
}

.adminMenubar .NavigationLink.active .NavIconBG svg [stroke="#0e344b"] {
  stroke: #fff;
}

.adminMenubar .NavigationLink[data-image="All Customer"] {
  position: relative;
}

.adminMenubar .adminSidebarNumberSet {
  display: none;
}

.adminMenubar .NavigationLink[data-image="All Customer"].active .adminSidebarNumberSet {
  color: #272727;
}

.adminMenubar .NavigationLink[data-image="All Customer"].active svg [fill="#0e344b"] {
  fill: #fff;
}

.adminMenubar .NavigationLink.active span {
  color: var(--primary);
}

@media screen and (max-width: 425px) {
  .header .header-menubar .headerHandler {
    column-gap: 0.5rem;
  }

  .header .header-menubar .headerLaunguage button img.languageIcon {
    display: block !important;
    margin-right: 0.2rem !important;
  }

  .header .header-menubar .headerLaunguage span {
    display: none;
  }

  .header .header-menubar .headerLaunguage button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .header-menubar .headerLaunguage ul li,
  .header .header-menubar .headerAccount ul li a {
    font-size: 14px;
  }

  .header .header-menubar .headerAccount ul li a img {
    width: 14px;
  }
}

@media screen and (max-width: 991px) {
  .header {
    min-height: 70px !important;
  }

  .header .header-logo img {
    width: 75px;
  }

  .header .header-menubar .headerLaunguage button {
    min-width: unset;
  }

  .header .header-menubar .headerLaunguage ul,
  .header .header-menubar .headerAccount ul {
    right: 0;
    left: unset;
  }
}

@media screen and (max-width: 767.98px) {
  .header .header-menubar .headerHandler .headerlogIn {
    border-radius: 50%;
    height: 36px;
    width: 36px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 992px) {
  .header .header-menubar .headerAccount {
    padding: 0px 10px 0px 0px;
    transition: all 0.5s ease;
    border-radius: 20px;
  }

  .header .header-menubar .headerAccount:hover,
  .header .header-menubar .headerAccount.is-active {
    background-color: var(--secondary);
  }
}

@media screen and (min-width: 1200px) {
  .headerUsername {
    text-overflow: ellipsis;
    max-width: 125px;
    overflow: hidden;
  }
}

/* Footer CSS */
.Footer .footerLinkList a {
  line-height: 2.5rem;
}

.Footer .SocialMedia {
  display: flex;
  gap: 0.5rem;
}

.Footer .SocialMedia a {
  width: 27px;
  height: 27px;
  background: var(--primary);
  border-radius: 6px;
  transition: all 0.25s ease;
}

.Footer .SocialMedia a:hover {
  background: var(--secondary);
}

.Footer .border-start {
  border-left-color: var(--primary) !important;
}

@media screen and (max-width: 575px) {
  .Footer .container {
    padding: 0 1.5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .CustomerIndex .footerSpace .f18 {
    font-size: 15px;
    line-height: 20px;
  }

  .CustomerIndex .Footer .footerLinkList a {
    line-height: 2rem;
    font-size: 14px;
  }

  .CustomerIndex .CopyRight > a {
    font-size: 14px;
    line-height: 25px;
  }
}

.goog-te-gadget {
  display: none !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}

body {
  top: 0 !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}
