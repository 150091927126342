/* --- RadioButton Custom --- */
.AdminIndex .brand-list .RadioBox {
  background: var(--primary-opacity);
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.AdminIndex .brand-list .RadioBox * {
  transition: all 0.5s ease;
}

.AdminIndex .brand-list .RadioBox:has(input:checked) {
  background: var(--primary);
}

.AdminIndex .brand-list .RadioBox input:checked + label {
  color: var(--light);
}

.AdminIndex .brand-list .RadioBox input[type="radio"] {
  accent-color: var(--light);
}

.AdminIndex .brand-list .carListitem .form-check-input:checked {
  border-color: var(--light);
}
/* --- RadioButton Custom End --- */
.AdminIndex .form-input-text {
  column-gap: 1rem;
  row-gap: 1rem;
}

.AdminIndex .car-description-textarea .CarInputBox textarea {
  background: var(--primary-opacity);
  min-height: 10.75rem;
  resize: none;
}

.AdminIndex .car-description-textarea textarea:focus {
  border-color: var(--dark);
}
/* --- Upload Image--- */
.AdminIndex .ant-upload-list-picture-card .ant-upload-list-item-container,
.AdminIndex .ant-upload-list-picture-card .ant-upload-select {
  width: 180px !important;
  height: 180px !important;
}

.AdminIndex .ant-upload-list-picture-card .ant-upload-list-item-done {
  padding: 0 !important;
  border-style: dashed !important;
}

.AdminIndex .ant-upload-list-picture-card .ant-upload-list-item-image {
  border-radius: 0.75rem;
}

.AdminIndex .ant-upload-list-picture-card .ant-upload-list-item::before {
  opacity: 1 !important;
  background-color: transparent !important;
}

.AdminIndex .ant-upload-list-item-actions > a {
  display: none !important;
}

.AdminIndex .ant-upload-list-item-action .anticon-delete {
  display: none;
}

.AdminIndex .ant-upload-list-item-actions {
  opacity: 1 !important;
  top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.AdminIndex .ant-upload-list-item-action {
  opacity: 1 !important;
}

.AdminIndex .ant-upload-list-item-action:before {
  background: url("https://res.cloudinary.com/dshhfnpgu/image/upload/v1679039565/close_mrfvbq.svg");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1 !important;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  display: block;
  position: absolute;
  right: 1rem;
  top: 0;
}

.AdminIndex .ant-upload-list-item-image {
  object-fit: cover !important;
}

.AdminIndex input::-webkit-outer-spin-button,
.AdminIndex input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* --- carInsurance--- */

.AdminIndex .FormSubmitButton.carSubmitButton {
  max-width: 13.75rem;
}

/* Works for Firefox */
.AuthIndex input[type="number"],
.AdminIndex input[type="number"],
.CustomerInput input[type="number"] {
  -moz-appearance: textfield;
}

.AuthIndex input[type="number"]::-webkit-inner-spin-button,
.AuthIndex input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.AdminIndex .AddForm .error-message,
.AdminIndex .AddForm .ChooseBrand .text-danger,
.addEmployeeModal .error-message,
.AdminIndex .error-message,
.error-message {
  font-family: "ff_rg";
  font-size: 0.75rem;
  line-height: 0.813rem;
  color: #dc3545 !important;
}

/* --- ActiveSwitch --- */

.ActiveSwitch button {
  background: var(--light-md);
  margin-left: 0.5rem;
}

.ActiveSwitch .ant-switch:focus-visible {
  outline: none !important;
}
/* --- View Car Details--- */
.ViewDetails .CarSpecification {
  padding: 1rem 1.5rem;
  margin: 0;
}

.ViewDetails .CarPhotosList {
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  display: flex;
}

.ViewDetails .CarPhotosList .listOfCars {
  min-width: 15rem;
  height: 15rem;
  width: 15rem;
}

.ViewDetails .CarPhotosList .listOfCars img {
  object-fit: cover;
  border-radius: 0.75rem;
  transition: all 0.25s ease;
}
.ViewDetails .ViewInsurance .ChildPriceSet {
  row-gap: 0;
}

.extraDetailsBackground {
  background: var(--primary-opacity);
  min-height: 5.375rem;
}

.AdminIndex .AddForm .error-message {
  margin-top: 4px;
}

.AdminIndex .AddCar .addCarInDatePicker.filled .ant-picker .ant-picker-clear {
  opacity: 1;
}
